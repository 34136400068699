<template>
    <div class="notFound">
      <div class="animate"><span>{</span><p>404</p><span>}</span></div>
      <p>Вы попали на несуществующую или удалённую страницу, вернитесь на главный экран</p>
      <router-link to="/">Вернуться</router-link>
    </div>
  </template>
  <script>
  export default {
    name: 'NotFound',
  };
  </script>