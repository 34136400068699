<template>
    <main>
        <div class="widthContainer">
        <div class="firstScreenPotolkiProject">
            <h2>Кейс</h2>
            <h1>Сайт для мастеров по натяжным потолкам</h1>
            <div class="lineBlock">
                <p>27.07.2024</p>
                <a>ссылка на проект</a>
            </div>
            <img src="../images/PotolkiProject/Topper.png" alt="">
            <p class="hashtags">#Одностраничник #Чистый код #Индивидуальный дизайн</p>
        </div>
        <div class="Exec">
            <h2>Задача</h2>
            <p>Сделать страницу которая сможет качественно продавать услуги специалистов по натяжным потолкам, с оценкой конкурентов, полностью индивидуальным дизайном и условиями</p>
        </div>
        <div class="aboutProject">
            <div class="defaultBlock">
                <img src="../images/PotolkiProject/FirstScreen.jpg" alt="">
                <div class="ScrollAbout">
                    В этом проекте мы использовали стандартный набор технологий, настраивали SEO и оптимизировали страницу по максимуму!
                </div>
            </div>
            <div class="defaultBlock">
                <div class="ScrollAbout">
                    Мы насытили страницу яркими элементами и полной информацией о том как работает фирма, добавили необычных сеток по сайту. Пользователи устали от однотипных сайтов.
                </div>
                <img src="../images/PotolkiProject/SecondScreen.jpg" alt="">
            </div>
            <div class="defaultBlock">
                <img src="../images/PotolkiProject/ThirdScreen.jpg" alt="">
                <div class="ScrollAbout">
                   В этой части сайта мы вынесли основные преимущества организации и форму визу, что бы любой кто заинтересовался, мог сразу оставить отклик. форма достаточная простая, человеку не будет лень её заполнять)
                </div>
            </div>
            <svg class="decorSvg" width="1422" height="3848" viewBox="0 0 1422 3848" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1204.15 106.105C1204.15 106.105 466.672 -46.9879 283.184 268.765C53.0556 664.778 1442.95 835.414 1086.62 1123.23C841.864 1320.93 475.503 1049.82 236.703 1254.69C-26.5406 1480.52 36.8434 1737.26 310.408 1950.47C540.27 2129.63 706.963 1836.35 993.664 1888.73C1331.64 1950.47 1339.61 2500.73 1015.58 2538.71C725.066 2572.76 45.2143 2835.55 337.632 2840.79C1803.5 2867.06 1514 3779.5 533 3779.5" stroke="#3952DF" stroke-width="137" stroke-linecap="round"/>
            </svg>
        </div>
        </div>
    </main>
</template>
  
  <script>

  </script>