<template>
    <main>
        <div class="widthContainer">
            <div class="FirstScreenSEO">
                <h1 class="mainTitleSEO">
                    SEO оптимизация
                </h1>
                <p class="subMainTitleSEO">
                    Это оптимизация сайта для улучшения его индексации и повышения позиций в поисковике. Маркетинговый
                    подход позволяет не только умножить органический трафик, но и увеличить конверсию сайта.
                </p>
            </div>
            <div class="bottomBlockSEO">
                <div class="defaultBlockSEO">
                    <h3>Полный цикл</h3>
                    <p>делаем полную техническую, семантическую и текстовую оптимизацию существующего (или создаваемого)
                        сайта, проводим аудит, сборку ядра, построение семантической структуры сайта, подготовка к
                        продвижению</p>
                </div>
                <div class="defaultBlockSEO">
                    <h3>Есть сайт?</h3>
                    <p>проведём бесплатный аудит, найдём слабые места, исправим ошибки и приготовим сайт для дальнейшей
                        рекламы</p>
                    <svg width="375" height="307" viewBox="0 0 375 307" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M71.8368 18C71.8368 18 27.204 145.435 71.8368 201.5C127.901 271.924 246.272 271.924 302.337 201.5C346.97 145.435 302.337 18 302.337 18"
                            stroke="black" stroke-width="104" />
                    </svg>
                    <svg width="375" height="307" viewBox="0 0 375 307" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M71.8368 18C71.8368 18 27.204 145.435 71.8368 201.5C127.901 271.924 246.272 271.924 302.337 201.5C346.97 145.435 302.337 18 302.337 18"
                            stroke="black" stroke-width="104" />
                    </svg>
                </div>
            </div>
        </div>
    </main>
</template>
  
<script>
export default {
    name: 'Seo',
};
</script>