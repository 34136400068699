<template>
    <main>
        <div class="widthContainer">
        <div class="firstScreenSalvadorProject">
            <h2>Кейс</h2>
            <h1>Мебельная компания “Сальвадор”</h1>
            <div class="lineBlock">
                <p>27.07.2024</p>
                <router-vue>ссылка на проект</router-vue>
            </div>
            <img src="../images//SalvadorProject/DecorTopper.png" alt="">
            <p class="hashtags">#Интернет-магазин #WordPress #Индивидуальный дизайн #SEO</p>
        </div>
        <div class="Exec">
            <h2>Задача</h2>
            <p>Разработка эффективного интернет-магазина, способного привлекать внимание и интерес оптовых покупателей. Создание современного, функционального и привлекательного сайта, который станет мощным инструментом для расширения клиентской базы и увеличения объемов продаж.</p>
        </div>
        <div class="aboutProject">
            <div class="defaultBlock">
                <img src="../images/SalvadorProject/FirstScreen.jpg" alt="">
                <div class="ScrollAbout">
                    В этом проекте мы использовали стандартный набор технологий, настраивали SEO и оптимизировали страницу по максимуму!
                </div>
            </div>
            <div class="defaultBlock">
                <div class="ScrollAbout">
                    Мы насытили страницу яркими элементами и полной информацией о том как работает фирма, добавили необычных сеток по сайту. Пользователи устали от однотипных сайтов.
                </div>
                <img src="../images/SalvadorProject/SecondScreen.jpg" alt="">
            </div>
            <div class="defaultBlock">
                <img src="../images/SalvadorProject/ThirdScreen.jpg" alt="">
                <div class="ScrollAbout">
                    В этом проекте мы использовали стандартный набор технологий, настраивали SEO и оптимизировали страницу по максимуму!
                </div>
            </div>
            <div class="defaultBlock">
                <div class="ScrollAbout">
                    Мы насытили страницу яркими элементами и полной информацией о том как работает фирма, добавили необычных сеток по сайту. Пользователи устали от однотипных сайтов.
                </div>
                <img src="../images/SalvadorProject/KatalogScreen.png" alt="">
            </div>
            <svg class="decorSvg" width="1387" height="3947" viewBox="0 0 1387 3947" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M907.001 156C906.001 120 929.901 47.4003 1033.5 45.0003C1163 42.0003 1160 276 1033.5 325.5C907.001 375 528.501 42 362.001 193C195.501 344 -7.99929 655 78.5007 827.5C165.001 1000 617.5 621 734.5 892C851.5 1163 510 1170 436 1108C362.001 1046 144.332 1340.47 122 1551.5C95.5981 1801 289.161 1908.56 436 2112C498.5 2212.83 705.501 2401.7 1033.5 2350.5C1443.5 2286.5 1423.5 2843.5 1103 2917.5C782.5 2991.5 232.501 2971.5 78.5007 3262.5C-75.4997 3553.5 331.5 3943.5 734.5 3899" stroke="#7E674D" stroke-width="88" stroke-linecap="round"/>
            </svg>
        </div>
        </div>
    </main>
</template>
  
  <script>  
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";


gsap.registerPlugin(ScrollTrigger);

  export default {
    name: 'Salvador',

    mounted() {

        const elementsToAnimate = this.$el.querySelectorAll('.firstScreenSalvadorProject');

gsap.to(elementsToAnimate, {
  scrollTrigger: {
    trigger: '.firstScreenSalvadorProject', // используем блок с классом '.Exec' в качестве триггера
    start: "-900px",
    end: "200px",
    scrub: true,
  },
  y: 200,
  scale: 0.5,
});
},
};

  
  </script>