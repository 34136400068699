import { createRouter, createWebHistory } from 'vue-router';
import Home from './components/views/Main.vue';
import Dev from "./components/views/Dev.vue";
import Seo from "./components/views/Seo.vue";
import Tech from "./components/views/Tech.vue";
import Design from './components/views/Design.vue';
import Cases from "./components/views/Cases.vue";
import Contacts from './components/views/Contacts.vue';
import Privacy from './components/views/Privacy.vue';
import NotFound from './components/views/NotFound.vue';
import Potolki from './components/views/Projects/ProjectPotolki.vue';
import Flowers from './components/views/Projects/ProjectFlowers.vue';
import Salvador from './components/views/Projects/ProjectSalvador.vue';
import Evak13 from './components/views/Projects/ProjectEvak13.vue';

const routes = [
  { 
    path: '/', 
    component: Home,
    meta: {
      title: 'Рувикон - создание и продвижение сайтов',
      description: 'Создание сайтов любой сложности. Быстро, качественно и недорого.',
      ogTitle: 'Рувикон - создание и продвижение сайтов',
      ogDescription: 'Создание сайтов любой сложности. Быстро, качественно и недорого.',
      ogImage: 'https://example.com/image.jpg'
    }
  },
  { 
    path: '/dev', 
    component: Dev,
    meta: {
      title: 'Разработка - Рувикон',
      description: 'Услуги по разработке веб-сайтов и приложений.',
      ogTitle: 'Разработка - Рувикон',
      ogDescription: 'Профессиональные услуги по разработке сайтов.',
      ogImage: 'https://example.com/dev-image.jpg'
    }
  },
  { 
    path: '/seo', 
    component: Seo,
    meta: {
      title: 'SEO Оптимизация - Рувикон',
      description: 'Профессиональная SEO оптимизация для вашего сайта.',
      ogTitle: 'SEO Оптимизация - Рувикон',
      ogDescription: 'Эффективные стратегии SEO для повышения видимости вашего сайта.',
      ogImage: 'https://example.com/seo-image.jpg'
    }
  },
  { 
    path: '/tech', 
    component: Tech,
    meta: {
      title: 'Техподдержка - Рувикон',
      description: 'Поддержка стабильной функциональности вашего сайта',
      ogTitle: 'Техподдержка - Рувикон',
      ogDescription: 'Поддержка стабильной функциональности вашего сайта',
      ogImage: 'https://example.com/tech-image.jpg'
    }
  },
  { 
    path: '/design', 
    component: Design,
    meta: {
      title: 'Дизайн - Рувикон',
      description: 'Креативный дизайн для вашего бизнеса.',
      ogTitle: 'Дизайн - Рувикон',
      ogDescription: 'Уникальные дизайнерские решения для вашего проекта.',
      ogImage: 'https://example.com/design-image.jpg'
    }
  },
  { 
    path: '/cases', 
    component: Cases,
    meta: {
      title: 'Наши проекты - Рувикон',
      description: 'Смотрите наши успешные проекты и кейсы.',
      ogTitle: 'Наши проекты - Рувикон',
      ogDescription: 'Изучите наши успешные кейсы и проекты.',
      ogImage: 'https://example.com/cases-image.jpg'
    }
  },
  { 
    path: '/contacts', 
    component: Contacts,
    meta: {
      title: 'Контакты - Рувикон',
      description: 'Свяжитесь с нами для получения консультации.',
      ogTitle: 'Контакты - Рувикон',
      ogDescription: 'Контактная информация для связи с нашей командой.',
      ogImage: 'https://example.com/contact-image.jpg'
    }
  },
  { 
    path: '/privacy', 
    component: Privacy,
    meta: {
      title: 'Политика конфиденциальности - Рувикон',
      description: 'Узнайте о нашей политике конфиденциальности.',
      ogTitle: 'Политика конфиденциальности - Рувикон',
      ogDescription: 'Информация о том, как мы обрабатываем ваши данные.',
      ogImage: 'https://example.com/privacy-image.jpg'
    }
  },
  { 
    path: '/cases/potolki', 
    component: Potolki,
    meta: {
      title: 'Проект Потолки - Рувикон',
      description: 'Сделали страницу которая сможет качественно продавать услуги специалистов по натяжным потолкам, с оценкой конкурентов, полностью индивидуальным дизайном и условиями',
      ogTitle: 'Проект Потолки - Рувикон',
      ogDescription: 'Сделали страницу которая сможет качественно продавать услуги специалистов по натяжным потолкам, с оценкой конкурентов, полностью индивидуальным дизайном и условиями',
      ogImage:'http://127.0.0.1:3000/src/components/views/images/PotolkiProject/Topper.png'
    }
  },
  { 
    path: '/cases/flowers', 
    component: Flowers,
    meta: {
      title: 'Проект Цветы - Рувикон',
      description: 'Создали страницу с привлекательным дизайном для увеличения объёма клиентов и улучшения поискового индексирования',
      ogTitle: 'Проект Цветы - Рувикон',
      ogDescription:  'Создали страницу с привлекательным дизайном для увеличения объёма клиентов и улучшения поискового индексирования',
      ogImage:'http://127.0.0.1:3000/src/components/views/images/FlowersKuzProject/FirstScreen.jpg'
    }
  },
  { 
    path:'/cases/salvador', 
    redirect:'/cases' 
  },
  { 
    path:'/cases/evak13', 
    component:Evak13,
     meta:{
       title:'Проект Evak13 - Рувикон',
       description:'Разработка максимально отзывчевого интерфейса, для осуществления быстродействия в виде звонка или заполнения формы на сайте. Подкрепили хорошим дизайном и упаковали всё в новейшие технологии.',
       ogTitle:'Проект Evak13 - Рувикон',
       ogDescription:'Разработка максимально отзывчевого интерфейса, для осуществления быстродействия в виде звонка или заполнения формы на сайте. Подкрепили хорошим дизайном и упаковали всё в новейшие технологии.',
       ogImage:'http://127.0.0.1:3000/src/components/views/images/Evak13Project/firstScreen.png'
     }
   },
   { 
     path:'/:catchAll(.*)', 
     redirect:'/notfound' 
   },
   { 
     path:'/notfound', 
     component:NotFound,
      meta:{
        title:'Упс!',
        description:'Несуществующая или удалённая страница',
        ogTitle:'Упс - страница не найдена',
        ogDescription:'Разработка максимально отзывчевого интерфейса, для осуществления быстродействия в виде звонка или заполнения формы на сайте. Подкрепили хорошим дизайном и упаковали всё в новейшие технологии.',
        ogImage:'http://127.0.0.1:3000/src/components/views/images/Evak13Project/firstScreen.png'
      }
   }
];

const router = createRouter({
  history:createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
     return { top :0 , behavior:'smooth' };
   }
});

export default router;