<template>
  <div id="app">
    <Header/>
    <router-view v-slot="{ Component }">
      <transition :name="transitionName">
        <component :is="Component" />
      </transition>
    </router-view>
    <Footer/>
  </div>
</template>

<script>
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
import "./styles/root.scss";
import "./styles/header.scss";
import "./styles/MainPage.scss";
import "./styles/DevPage.scss";
import "./styles/Design.scss";
import "./styles/Contacts.scss";
import "./styles/Cases.scss";
import "./styles/Seo.scss";
import "./styles/privacy.scss";
import "./styles/Tech.scss";
import "./styles/Footer.scss";
import "./styles/Projects/Projects.scss";
import "./styles/Projects/ProjectPotolki.scss";
import "./styles/Projects/ProjectFlowers.scss";
import "./styles/Projects/ProjectSalvador.scss";
import "./styles/Projects/ProjectEvak13.scss";
import "./styles/NotFound.scss";
export default {
  name: 'App',

  components: {
    Header,
    Footer,
  },
  
  data() {
    return {
      transitionName: 'fade'
    }
  },

  watch: {
    '$route' (to, from) {
      this.transitionName = to.path === from.path ? 'fade' : (to.path.length < from.path.length ? 'slide-right' : 'slide-left');
      
      // Обновляем заголовок, описание и OG мета-теги
      this.updateMetaTags(to.meta);
    }
  },

  mounted() {
    // Устанавливаем начальные значения при монтировании
    this.updateMetaTags(this.$route.meta);
  },

  methods: {
    updateMetaTags(meta) {
      if (meta && meta.title) {
        document.title = meta.title;

        // Обновляем стандартные мета-теги
        this.updateMetaTag('description', meta.description);
        
        // Обновляем OG мета-теги
        this.updateMetaTag('og:title', meta.ogTitle);
        this.updateMetaTag('og:description', meta.ogDescription);
        this.updateMetaTag('og:image', meta.ogImage);
      }
    },

    updateMetaTag(name, content) {
      if (content) {
        const existingTag = document.querySelector(`meta[name="${name}"]`) || document.querySelector(`meta[property="${name}"]`);
        if (existingTag) {
          existingTag.setAttribute('content', content);
        } else {
          const newMetaTag = document.createElement('meta');
          if (name.startsWith('og:')) {
            newMetaTag.setAttribute('property', name);
          } else {
            newMetaTag.setAttribute('name', name);
          }
          newMetaTag.content = content;
          document.head.appendChild(newMetaTag);
        }
      }
    }
  }
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s, transform 0.5s;
  transition-delay: 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(50px);
}
.fade-enter-to,
.fade-leave {
  opacity: 1;
  transform: translateY(0);
}


.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  transition:  .6s;
  transform: translateY(1000px);
}
.slide-right-enter,
.slide-right-leave-to,
.slide-left-enter,
.slide-left-leave-to {
  transform: translateY(200px) scale(.95);
  opacity: 0;
  filter: blur(15px);
  z-index: -3;
}
</style>