<template>
    <main class="Evak13Project">.
        <div class="widthContainer">
        <div class="firstScreenEvak13Project">
            <h2>Кейс</h2>
            <h1>Эвакуаторы Evak13</h1>
            <div class="lineBlock">
                <p>23.10.2024</p>
                <a href="https://evak-13.ru/">ссылка на проект</a>
            </div>
           <img src="../images/Evak13Project/firstScreen.png" alt="">
            <p class="hashtags">#Лендинг #для_Быстродействия #Индивидуальный дизайн #SEO</p>
        </div>
        <div class="Exec">
            <h2>Задача</h2>
            <p>Разработка максимально отзывчевого интерфейса, для осуществления быстродействия в виде звонка или заполнения формы на сайте. Подкрепили хорошим дизайном и упаковали всё в новейшие технологии.</p>
        </div>
        <div class="aboutProject">
            <div class="defaultBlock">
                <img class="WhiteBackGroundImage" src="../images/Evak13Project/one.png" alt="">
                <div class="ScrollAbout">
                    Сделали страницу запоминающейся и с выраженным брендированием
                </div>
            </div>
            <div class="defaultBlock">
                <div class="ScrollAbout">
                    Наполнили информационную составляющую
                </div>
                <img class="WhiteBackGroundImage" src="../images/Evak13Project/two.png" alt="">
            </div>
            <div class="defaultBlock">
                <div class="ScrollAbout">
                    Тут немного взаимодейсствия с клиентами
                </div>
                <img class="WhiteBackGroundImage" src="../images/Evak13Project/three.png" alt="">
            </div>
            <div class="defaultBlock">
                <div class="ScrollAbout">
                    Ну и нижняя форма для брони или предзаказов
                </div>
                <img class="WhiteBackGroundImage" src="../images/Evak13Project/four.png" alt="">
            </div>
            <svg class="decorSvg" width="1374" height="3456" viewBox="0 0 1374 3456" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M893.999 156C892.999 120 916.899 47.4003 1020.5 45.0003C1150 42.0003 1147 276 1020.5 325.5C893.999 375 515.499 42 348.999 193C182.499 344 -21.0006 655 65.4994 827.5C151.999 1000 604.499 621 721.499 892C838.499 1163 779 1340.5 676.5 1401C593.362 1450.07 131.33 1340.47 108.999 1551.5C82.5968 1801 276.16 1908.56 422.999 2112C485.499 2212.83 692.5 2401.7 1020.5 2350.5C1430.5 2286.5 1410.5 2843.5 1090 2917.5C769.499 2991.5 263 2477.5 108.999 2768.5C-45.001 3059.5 283 3452.5 686 3408" stroke="#5D391A" stroke-width="88" stroke-linecap="round"/>
            </svg>

        </div>
    </div>
    </main>
</template>
  
  <script>

  </script>