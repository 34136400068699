<template>
    <main>
      <div class="widthContainer">
        <div class="privacy">
          <div class="defaultBLock"> 
            <h2>Политика конфиденциальности</h2>
            <p>
              Настоящая Политика конфиденциальности определяет, каким образом рувикон.рф осуществляет сбор, использование, хранение, раскрытие информации, полученной от пользователей на веб-сайте <a href="https://рувикон.рф">https://рувикон.рф</a>. Данная политика конфиденциальности относится к Сайту, всем поддоменам Сайта, всем продуктам и услугам, предлагаемым «Рувикон».
            </p>
            <p>
              Содержание этой страницы - сведения о получаемой нами или третьими лицами информации в момент Вашего посещения Сайта. Подобная политика является залогом того, что пользователь будет ответственнее относится к предоставлению личных данных.
            </p>
            <p>
              Настоящая Политика конфиденциальности действует только на этом Сайте и в отношении информации, предоставляемой здесь; не может распространяться на других сайтах, в том числе, содержащих упоминание о нашем Сайте или ссылки, ведущие на него.
            </p>
          </div>
          <div class="defaultBLock">
            <h3>Получаемые данные</h3>
            <p>Во время посещения Вами Сайта определяется IP адрес, имя домена, с которого пришли (например, «google.ru») и страна регистрации данного IP. Помимо этого, фиксируются постраничные переходы пользователей.</p>
            <p>Процесс получения на Сайте сведений имеет своей целью, в том числе, упрощение в пользовании Сайтом. Здесь фиксируется только информация, добровольно предоставляемая Вашим браузером.</p>
            <p>На Сайте применяется стандартная технология «cookies» («куки»), позволяющая адаптировать стиль отображения Сайта под параметры экрана монитора. «Куки» - сохраняемые на жестком диске Вашего компьютера данные с веб-сайта. Они содержат материалы, необходимые для настройки Сайта и сохранения вариантов просмотра, выбранных Вами при посещении. В понятие «сбора статистической информации» входят данные о посещенных страницах, загрузках, имени домена интернет-провайдера, стране посетителя, адреса прочих веб-ресурсов, с которых совершались переходы на Сайт и далее.</p>
            <p>Данную технологию используют счетчики компании Яндекс, установленные на Сайте.</p>
            <p>Личные сведения, которые касаются непосредственно Вас, технология «Cookies» не содержит. Если Вы не хотите просматривать веб-страницы с «cookies», настройте браузер так, чтобы он не принимал «cookies», или уведомлял об их посылке.</p>
          </div>

          <div class="defaultBLock">
            <h3>Как используется собранная информация</h3>
            <p><a href="https://рувикон.рф">https://рувикон.рф</a> оставляет за собой право на сбор и использование личных данных пользователей для следующих целей:</p>
            <p>Улучшение качества обслуживания клиентов. Информация, которую оставляет пользователь, помогает более эффективно и быстро реагировать на запросы.</p>
            <p>Непосредственно улучшение Сайта. В целях повышения эффективности работы, качества предлагаемых услуг, используется обратная связь.</p>
            <p>Для отправления пользователям материалов по интересующим их темам. Происходит с Вашего согласия.</p>
         </div>
 
         <div class="defaultBLock">
            <h3>Как осуществляется защита Ваших данных</h3>
            <p>Строго соблюдаются все меры безопасности по сбору, хранению и обработке данных, предотвращающие несанкционированный доступ к ним, изменение, раскрытие, уничтожение </p>
         </div>
           <div class="defaultBLock">
            <h3>Общий доступ к личной информации</h3>
            <p>Персонифицированная информация пользователей нами не продается, не обменивается, не сдается в аренду. Данные о таргетации и геолокации могут быть переданы Партнерам и Рекламодателям для вышеописанных целей. Нами предусмотрено использование услуг сторонних поставщиков для помощи в управлении Сайтом (рассылки, сбор статистики и др.).</p>
         </div>
         
           <div class="defaultBLock">
            <h3>Изменения в политике конфиденциальности</h3>
            <p><a href="https://рувикон.рф">https://рувикон.рф</a> в праве по собственному усмотрению обновлять данную Политику конфиденциальности в любое время. При этом публикуется соответствующая информация</p>
         </div>

          <div class="defaultBLock">
            <h3>Ваше согласие с этими условиями</h3>
            <p>Используя Сайт, Вы выражаете свое согласие с этой политикой. Пожалуйста, воздержитесь от пользования нашим Сайтом, если не согласны с его политикой. Использование Вами Сайта после внесенных в настоящую политику изменений, будет рассмотрено как Ваше согласие с данными нововведениями.</p>
         </div>
         <div class="defaultBLock">
          <h3>Отказ от ответственности</h3>
          <p>Политика конфиденциальности при посещении третьими лицами других Сайтов, не попадает под действия данного документа. Администрация Сайта ответственности за действия других веб-ресурсов не несет.</p>
         </div>
                  <div class="defaultBLock">
          <h3>Как с нами связаться</h3>
                      <p>Если у Вас есть какие-либо вопросы по политике конфиденциальности, использованию Сайта, или иным вопросам, связанным с Сайтом, пожалуйста, свяжитесь с нами по почте: <a href="mailto:hello@рувикон.рф">hello@рувикон.рф</a></p>

         </div>
        </div>
      </div>
    </main>
  </template>
  
  <script>
  export default {
    name: 'Privacy',
  };
  </script>