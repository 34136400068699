<template>
  <main class="TechMain">
    <div class="widthContainer">
      <div class="techFirstScreen">
        <div class="left">
          <h1>Тех поддержка</h1>
          <ul>
            <li>
              Контроль работоспособности сайта
            </li>
            <li>
              Предотвращение вирусных атак
            </li>
            <li>
              Размещение актуальной информации
            </li>
            <li>
              Внедрение нового функционала
            </li>
            <li>
              Персональный менеджер для оперативного решения задач
            </li>
          </ul>
        </div>
        <svg width="332" height="373" viewBox="0 0 332 373" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect class="one" y="204.289" width="66.1153" height="160" fill="#3952DF"/>
          <rect class="two" x="88.4014" y="146.346" width="66.1153" height="225" fill="#B5ED30"/>
          <rect class="three" x="176.803" y="80.9727" width="66.1153" height="290" fill="#3952DF"/>
          <rect class="four" x="265.203" width="66.1153" height="370" fill="#B5ED30"/>
        </svg>
      </div>
      <div class="rates">
        <h2>Тарифы</h2>
        <div class="priceBlock">
        <div class="defaultBlock">
          <p class="topper">По предоплате</p>
          <p class="price">810 ₽/час</p>
          <p class="desk">Формат подойдёт для проектов, у которых есть запланированный объём работ по развитию проекта — чем выше тариф, тем выгоднее ставка часа.</p>
        </div>
        <div class="defaultBlock">
          <p class="topper">Сдельно</p>
          <p class="price">990 ₽/час</p>
          <p class="desk">Формат подойдёт для проектов, у которых нет ежемесячной потребности в работе по поддержке и доработкам сайта.</p>
        </div>
      </div>
    </div>
    <div class="stack">
      <h2>Стек технологий</h2>
      <div class="gridStack">
          <img src="./images/DevPage/Stack/HTML.png" alt="разработка сайтов под ключ москва ключ разработка сайта цена разработка сайтов москва цены">
          <img src="./images/DevPage/Stack/Vue.png" alt="техническая поддержка техническая поддержка сайта купить сайт создать страницу">
          <img src="./images/DevPage/Stack/Angular.png" alt="создание интернета веб разработчик создание веб страницы веб программист разработка">
          <img src="./images/DevPage/Stack/Vite.png" alt="айти айтишник developer айти софт айти специалист веб разработчик программа для просмотра веб страниц">
          <img src="./images/DevPage/Stack/GSAP.png" alt="веб разработчик что такое веб сайт web сайт это как называется программа для просмотра веб страниц">
          <img src="./images/DevPage/Stack/SASS.png" alt="веб разработчик это лучшие сайты своя компания красивые сайты">
          <img src="./images/DevPage/Stack/WordPress.png" alt="разработка сайта компании digital агентство создать лендинг">
          <img src="./images/DevPage/Stack/JavaScript.png" alt="создать логотип компании как открыть свой интернет магазин business studio">
          <img src="./images/DevPage/Stack/PHP.png" alt="digital агентство создать лендинг бесплатно стадии разработки стартап проекта">
          <img src="./images/DevPage/Stack/CSS.png" alt="верстка сайта это business plan диджитал это техническая поддержка сайта купить сайт создать страницу">
        </div>
    </div>
    <div class="isIncluded">
      <h2>Что входит в поддержку веб-ресурса?</h2>
      <div class="grid">
        <div class="defaultBlock">
          <p>Широкий технологический стек</p>
          <p>Разработка, доработка и поддержка функционала на различных CMS, языках, фреймворках</p>
        </div>
        <div class="defaultBlock">
          <p>Регулярное обновление ПО</p>
          <p>Поддержание программного обеспечения сайта в актуальном состоянии за счет регулярных обновлений</p>
        </div>
        <div class="defaultBlock">
          <p>Мониторинг 24/7</p>
          <p>Непрерывный контроль работоспособности сайта и предотвращение возможных проблем заранее  </p>
        </div>
        <div class="defaultBlock">
          <p>Быстрое реагирование</p>
          <p>Умеем быстро возвращать сайт к жизни</p>
        </div>
        <div class="defaultBlock">
          <p>Прозрачный формат работы</p>
          <p>Отчеты о выполненных задачах и затраченном времени</p>
        </div>
      </div>
    </div>
  </div>
  </main>
</template>
  
<script>
export default {
  name: 'Tech',
};
</script>