<template>
    <main>
        <div class="widthContainer">
            <div class="firstScreenDev">
                <div class="left">
                    <h1>Комплексная разработка сайтов для бизнеса</h1>
                    <p>В Рувикон разрабатывают сложные и простые веб продукты. Мы верим в дизайн основаный на данных и
                        удобности использованияее</p>
                    <button @click="ToFooter()">Сколько будет стоить мой сайт?</button>
                </div>
                <img src="./images/DevPage/FirstScreenImage.png" alt="Разработка сайтов лендингов главная картинка дешево">
            </div>
            <div class="creatingBuisnessDevPage">
                <div class="scrollLeft">
                    <h2>Создаём не просто сайты</h2>
                </div>
                <div class="right">
                    <div class="defaultBlock">
                        <h3>Создаём из идеи востребованный продукт</h3>
                        <p>Превратим вашу идею в рабочий продукт который сможет приносить доход</p>
                    </div>
                    <div class="defaultBlock">
                        <h3>Мы готовы быть инициаторами развития проекта</h3>
                        <p>Развиваем ваш продукт с вами и нашими отлаженными процессами, сами подскажем как лучше, вы только
                            принимаете решения</p>
                    </div>
                    <div class="defaultBlock">
                        <h3>Уделяем внимание мелочам</h3>
                        <p>Даже надписям на кнопках, С помощью мощного анализа рынка мы делаем продукты на которые обращают
                            внимание</p>
                    </div>
                </div>
            </div>
            <div class="stackTechnologiesDevPage">
                <h2>Современный стек, отлаженные процессы и поддержка клиентов - наши сильные стороны</h2>
                <div class="gridStack">
                    <img src="./images/DevPage/Stack/HTML.png" alt="Стек студии веб-студии по разработке сайтов">
                    <img src="./images/DevPage/Stack/Vue.png" alt="веб студия веб студия сайт студия веб дизайна продвижение веб студии">
                    <img src="./images/DevPage/Stack/Angular.png" alt="веб студия продвижения сайтов студия веб разработки веб студия москва">
                    <img src="./images/DevPage/Stack/Vite.png" alt="веб студия тверь веб студия разработка сайтов веб студии seo">
                    <img src="./images/DevPage/Stack/GSAP.png" alt="веб студия создание лучшие веб студии топ веб студий">
                    <img src="./images/DevPage/Stack/SASS.png" alt="веб студия создание сайтов сео веб студия компания веб студия">
                    <img src="./images/DevPage/Stack/WordPress.png" alt="веб студии россии веб студия мастер разработка сайтов">
                    <img src="./images/DevPage/Stack/JavaScript.png" alt="разработка сайта под разработка сайта под ключ">
                    <img src="./images/DevPage/Stack/PHP.png" alt="ключ разработка сайта разработка сайта компании стоимость разработки сайта">
                    <img src="./images/DevPage/Stack/CSS.png" alt="разработка дизайна сайта разработка сайтов москва разработка веб сайтов разработка сайта цена">
                </div>
            </div>
            <div class="StartProductDevPage">
                <div class="left">
                    <h2>Запуск вашего продукта</h2>
                    <div class="defaultBlock">
                        <div class="top">
                            <p class="numb">01</p>
                            <h3>Планирование</h3>
                        </div>
                        <p>Проводим первичный брифинг, определяем цели и задачи. Формируем видение процесса и обсуждаем его
                            со всеми участниками — так синхронизируемся с клиентом и минимизируем риски.</p>
                    </div>
                    <div class="defaultBlock">
                        <div class="top">
                            <p class="numb">02</p>
                            <h3>Аналитика</h3>
                        </div>
                        <p>Проводим углубленные интервью, чтобы узнать нужную информацию о клиенте, цели и задачи сайта или
                            сервиса. Чтобы будущий продукт выделялся среди конкурентов проводим аналитику рынка и
                            конкурентов.</p>
                    </div>
                    <div class="defaultBlock">
                        <div class="top">
                            <p class="numb">03</p>
                            <h3>Прототипирование</h3>
                        </div>
                        <p>Проектируем структуру сайта или сервиса, прорабатываем прототипы: расставляем акценты, учитывая
                            цели и особенности клиента. Следим, чтобы навигация была интуитивно понятной пользователям.</p>
                    </div>
                    <div class="defaultBlock">
                        <div class="top">
                            <p class="numb">04</p>
                            <h3>Дизайн</h3>
                        </div>
                        <p>Прорабатываем смыслы, тексты, визуалы под особенности заказчика. Проектируем дизайн, анимации,
                            3D-решения, которые запоминаются и мотивируют пользователя скроллить вниз и совершить конверсию.
                        </p>
                    </div>
                    <div class="defaultBlock">
                        <div class="top">
                            <p class="numb">05</p>
                            <h3>Разработка</h3>
                        </div>
                        <p>Реализуем функционал сервиса на бэкенде, наполняем его контентом. Тестируем и улучшаем проект на
                            тестовом сервере. Оптимизируем сайт под SEO-продвижение.</p>
                    </div>
                    <div class="defaultBlock">
                        <div class="top">
                            <p class="numb">06</p>
                            <h3>Запуск</h3>
                        </div>
                        <p>Запускаем сайт на основном домене и тестируем функционал.</p>
                    </div>
                    <div class="defaultBlock">
                        <div class="top">
                            <p class="numb">07</p>
                            <h3>Поддержка и SEO</h3>
                        </div>
                        <p>Обеспечиваем стабильную работу сайта в любое время суток (первый месяц после запуска сайта —
                            бесплатно). Проводим SEO-продвижение, используем только «белые» методы</p>
                    </div>
                </div>
                <div class="planeScrollRight">
                    <svg width="356" height="356" viewBox="0 0 356 356" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_5782_220)">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M177.664 346.664C191.533 346.686 202.074 332.889 202.058 318.947L202.141 248.161L292.426 206.997C300.35 202.329 308.796 198.095 308.774 182.459C308.768 174.485 308.746 157.904 284.196 157.881L202.236 166.05L202.296 107.466L236.498 86.9449C241.599 83.9332 243.516 80.3825 243.299 75.9983C243.038 73.6367 245.011 60.3729 234.375 60.3618L177.814 67.4242L121.253 60.3618C113.551 60.3507 112.384 66.3295 112.14 75.8536C112.145 82.0493 114.407 82.9775 119.858 86.5615L153.209 107.555L153.309 165.9L71.3924 157.665C46.8485 157.637 46.8707 174.229 46.8652 182.192C46.8874 197.84 55.3337 202.085 63.2686 206.764L153.459 248.244L153.581 318.947C153.592 332.884 163.794 346.653 177.664 346.664Z"
                                fill="#ADFF00" />
                        </g>
                        <defs>
                            <clipPath id="clip0_5782_220">
                                <rect width="251.467" height="251.467" fill="white"
                                    transform="translate(355.627 177.813) rotate(135)" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            </div>
        </div>
    </main>
</template>
  
<script>
export default {
    name: 'Dev',



    mounted() {
        this.ToFooter();
    },
    methods: {
        ToFooter() {
            window.scroll({
                top: document.body.scrollHeight,
                behavior: 'smooth',
            });
        }
    }
};
</script>