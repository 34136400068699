<template>
    <main>
        <div class="firstScreenFlowersProject">
            <div class="widthContainer">
            <h2>Кейс</h2>
            <h1>Сайт для магазина цветов</h1>
            <div class="lineBlock">
                <p>27.07.2024</p>
            </div>
            <svg class="svgFlowersBlock" width="1583" height="392" viewBox="0 0 1583 392" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M124.077 85.8543C128.379 75.2009 120.509 61.2643 112.218 52.4731C115.432 40.8102 115.854 24.8155 107.062 17.4355C98.2713 10.0458 82.5901 13.2212 71.6525 18.396C61.5578 11.7315 46.4843 6.38033 36.7326 12.4568C26.9907 18.5332 25.1678 34.4398 26.7065 46.4358C17.2586 53.9726 7.50691 66.6645 10.2805 77.8079C13.0541 88.9513 27.6082 95.606 39.4866 97.8405C43.7401 109.16 52.796 122.352 64.253 123.166C72.368 123.734 80.1301 117.961 86.0498 110.787C98.0066 125.684 106.455 141.434 111.091 156.909C104.005 146.325 89.333 137.21 58.8038 137.21C58.8038 177.932 88.6176 186.243 107.807 186.243L139.17 186.262C139.17 159.575 129.506 128.34 106.925 99.0264C114.599 96.6056 121.421 92.3913 124.077 85.8543ZM81.1788 83.6981C78.5063 85.3655 75.5315 86.4901 72.4243 87.0078C69.3171 87.5255 66.1384 87.4262 63.0696 86.7154C60.0008 86.0046 57.102 84.6964 54.5388 82.8653C51.9756 81.0343 49.7982 78.7163 48.1308 76.0437C46.4635 73.3712 45.3388 70.3964 44.8211 67.2892C44.3034 64.182 44.4028 61.0033 45.1136 57.9345C45.8243 54.8657 47.1326 51.967 48.9636 49.4038C50.7947 46.8406 53.1126 44.6631 55.7852 42.9958C61.1827 39.6297 67.6962 38.5456 73.893 39.9819C80.0898 41.4183 85.4622 45.2576 88.8283 50.655C92.1944 56.0525 93.2785 62.5661 91.8421 68.7628C90.4057 74.9596 86.5665 80.332 81.169 83.6981H81.1788ZM137.405 120.039C148.02 141.307 153.763 163.682 153.851 185.753C186.174 162.515 186.213 119.627 186.213 78.4351C186.213 78.4351 150.244 88.5691 137.405 120.039Z" fill="#A9B3EB"/>
                <path d="M303.918 110.16C299.615 120.813 307.485 134.75 315.776 143.541C312.562 155.204 312.14 171.199 320.932 178.579C329.723 185.968 345.404 182.793 356.342 177.618C366.436 184.283 381.51 189.634 391.262 183.557C401.003 177.481 402.826 161.574 401.288 149.578C410.736 142.042 420.487 129.35 417.714 118.206C414.94 107.063 400.386 100.408 388.508 98.1736C384.254 86.8538 375.198 73.6621 363.741 72.8486C355.626 72.2802 347.864 78.0528 341.944 85.2269C329.988 70.3298 321.539 54.5801 316.904 39.1048C323.989 49.6895 338.661 58.8042 369.19 58.8042C369.19 18.0823 339.377 9.77127 320.187 9.77127L288.825 9.75167C288.825 36.439 298.488 67.6738 321.069 96.9877C313.395 99.4085 306.574 103.623 303.918 110.16ZM346.815 112.316C349.488 110.649 352.463 109.524 355.57 109.006C358.677 108.489 361.856 108.588 364.925 109.299C367.993 110.01 370.892 111.318 373.455 113.149C376.018 114.98 378.196 117.298 379.863 119.97C381.531 122.643 382.655 125.618 383.173 128.725C383.691 131.832 383.591 135.011 382.881 138.08C382.17 141.148 380.862 144.047 379.031 146.61C377.199 149.174 374.882 151.351 372.209 153.018C366.811 156.384 360.298 157.469 354.101 156.032C347.904 154.596 342.532 150.757 339.166 145.359C335.8 139.962 334.716 133.448 336.152 127.251C337.588 121.055 341.428 115.682 346.825 112.316L346.815 112.316ZM290.589 75.975C279.974 54.7075 274.231 32.3325 274.143 10.2613C241.82 33.4988 241.781 76.3867 241.781 117.579C241.781 117.579 277.75 107.445 290.589 75.975Z" fill="#A9B3EB"/>
                <path d="M586.898 85.8543C591.161 75.2009 583.364 61.2643 575.148 52.4731C578.333 40.8102 578.751 24.8155 570.04 17.4355C561.33 10.0458 545.792 13.2212 534.955 18.396C524.953 11.7315 510.018 6.38033 500.356 12.4568C490.703 18.5332 488.897 34.4398 490.422 46.4358C481.06 53.9726 471.398 66.6645 474.146 77.8079C476.894 88.9513 491.315 95.606 503.084 97.8405C507.299 109.16 516.272 122.352 527.624 123.166C535.664 123.734 543.355 117.961 549.22 110.787C561.068 125.684 569.438 141.434 574.031 156.909C567.011 146.325 552.473 137.21 522.224 137.21C522.224 177.932 551.765 186.243 570.778 186.243L601.853 186.262C601.853 159.575 592.278 128.34 569.904 99.0264C577.508 96.6056 584.267 92.3913 586.898 85.8543ZM544.394 83.6981C541.746 85.3655 538.799 86.4901 535.72 87.0078C532.641 87.5255 529.492 87.4262 526.451 86.7154C523.41 86.0046 520.538 84.6964 517.999 82.8653C515.459 81.0343 513.301 78.7163 511.649 76.0437C509.997 73.3712 508.883 70.3964 508.37 67.2892C507.857 64.182 507.955 61.0033 508.66 57.9345C509.364 54.8657 510.66 51.967 512.474 49.4038C514.289 46.8406 516.585 44.6631 519.233 42.9958C524.581 39.6297 531.035 38.5456 537.175 39.9819C543.315 41.4183 548.638 45.2576 551.973 50.655C555.309 56.0525 556.383 62.5661 554.96 68.7628C553.536 74.9596 549.732 80.332 544.384 83.6981H544.394ZM600.105 120.039C610.622 141.307 616.312 163.682 616.4 185.753C648.426 162.515 648.465 119.627 648.465 78.4351C648.465 78.4351 612.826 88.5691 600.105 120.039Z" fill="#A9B3EB"/>
                <path d="M766.08 110.16C761.777 120.813 769.647 134.75 777.939 143.541C774.724 155.204 774.303 171.199 783.094 178.579C791.885 185.968 807.566 182.793 818.504 177.618C828.598 184.283 843.672 189.634 853.424 183.557C863.166 177.481 864.988 161.574 863.45 149.578C872.898 142.042 882.649 129.35 879.876 118.206C877.102 107.063 862.548 100.408 850.67 98.1736C846.416 86.8538 837.36 73.6621 825.903 72.8486C817.788 72.2802 810.026 78.0528 804.106 85.2269C792.15 70.3298 783.701 54.5801 779.066 39.1048C786.152 49.6895 800.823 58.8042 831.352 58.8042C831.352 18.0823 801.539 9.77127 782.349 9.77127L750.987 9.75167C750.987 36.439 760.65 67.6738 783.231 96.9877C775.557 99.4085 768.736 103.623 766.08 110.16ZM808.977 112.316C811.65 110.649 814.625 109.524 817.732 109.006C820.839 108.489 824.018 108.588 827.087 109.299C830.155 110.01 833.054 111.318 835.617 113.149C838.181 114.98 840.358 117.298 842.025 119.97C843.693 122.643 844.817 125.618 845.335 128.725C845.853 131.832 845.753 135.011 845.043 138.08C844.332 141.148 843.024 144.047 841.193 146.61C839.362 149.174 837.044 151.351 834.371 153.018C828.974 156.384 822.46 157.469 816.263 156.032C810.066 154.596 804.694 150.757 801.328 145.359C797.962 139.962 796.878 133.448 798.314 127.251C799.751 121.055 803.59 115.682 808.987 112.316L808.977 112.316ZM752.751 75.975C742.137 54.7075 736.393 32.3325 736.305 10.2613C703.982 33.4988 703.943 76.3867 703.943 117.579C703.943 117.579 739.912 107.445 752.751 75.975Z" fill="#A9B3EB"/>
                <path d="M1050.86 85.8543C1055.12 75.2009 1047.32 61.2643 1039.11 52.4731C1042.29 40.8102 1042.71 24.8155 1034 17.4355C1025.29 10.0458 1009.75 13.2212 998.915 18.396C988.913 11.7315 973.978 6.38033 964.316 12.4568C954.663 18.5332 952.857 34.4398 954.381 46.4358C945.02 53.9726 935.358 66.6645 938.106 77.8079C940.854 88.9513 955.275 95.606 967.044 97.8405C971.259 109.16 980.232 122.352 991.584 123.166C999.624 123.734 1007.31 117.961 1013.18 110.787C1025.03 125.684 1033.4 141.434 1037.99 156.909C1030.97 146.325 1016.43 137.21 986.184 137.21C986.184 177.932 1015.72 186.243 1034.74 186.243L1065.81 186.262C1065.81 159.575 1056.24 128.34 1033.86 99.0264C1041.47 96.6056 1048.23 92.3913 1050.86 85.8543ZM1008.35 83.6981C1005.71 85.3655 1002.76 86.4901 999.68 87.0078C996.601 87.5255 993.452 87.4262 990.411 86.7154C987.37 86.0046 984.498 84.6964 981.958 82.8653C979.419 81.0343 977.261 78.7163 975.609 76.0437C973.957 73.3712 972.843 70.3964 972.33 67.2892C971.817 64.182 971.915 61.0033 972.62 57.9345C973.324 54.8657 974.62 51.967 976.434 49.4038C978.249 46.8406 980.545 44.6631 983.193 42.9958C988.541 39.6297 994.995 38.5456 1001.14 39.9819C1007.28 41.4183 1012.6 45.2576 1015.93 50.655C1019.27 56.0525 1020.34 62.5661 1018.92 68.7628C1017.5 74.9596 1013.69 80.332 1008.34 83.6981H1008.35ZM1064.06 120.039C1074.58 141.307 1080.27 163.682 1080.36 185.753C1112.39 162.515 1112.42 119.627 1112.42 78.4351C1112.42 78.4351 1076.79 88.5691 1064.06 120.039Z" fill="#A9B3EB"/>
                <path d="M1229.38 110.16C1225.12 120.813 1232.91 134.75 1241.13 143.541C1237.94 155.204 1237.53 171.199 1246.24 178.579C1254.95 185.968 1270.48 182.793 1281.32 177.618C1291.32 184.283 1306.26 189.634 1315.92 183.557C1325.57 177.481 1327.38 161.574 1325.86 149.578C1335.22 142.042 1344.88 129.35 1342.13 118.206C1339.38 107.063 1324.96 100.408 1313.19 98.1736C1308.98 86.8538 1300.01 73.6621 1288.65 72.8486C1280.61 72.2802 1272.92 78.0528 1267.06 85.2269C1255.21 70.3298 1246.84 54.5801 1242.25 39.1048C1249.27 49.6895 1263.8 58.8042 1294.05 58.8042C1294.05 18.0823 1264.51 9.77127 1245.5 9.77127L1214.42 9.75168C1214.42 36.439 1224 67.6738 1246.37 96.9877C1238.77 99.4085 1232.01 103.623 1229.38 110.16ZM1271.88 112.316C1274.53 110.649 1277.48 109.524 1280.56 109.006C1283.64 108.489 1286.79 108.588 1289.83 109.299C1292.87 110.01 1295.74 111.318 1298.28 113.149C1300.82 114.98 1302.98 117.298 1304.63 119.97C1306.28 122.643 1307.39 125.618 1307.91 128.725C1308.42 131.832 1308.32 135.011 1307.62 138.08C1306.91 141.148 1305.62 144.047 1303.8 146.61C1301.99 149.174 1299.69 151.351 1297.04 153.018C1291.7 156.384 1285.24 157.469 1279.1 156.032C1272.96 154.596 1267.64 150.757 1264.3 145.359C1260.97 139.962 1259.89 133.448 1261.32 127.251C1262.74 121.055 1266.55 115.682 1271.89 112.316L1271.88 112.316ZM1216.17 75.975C1205.66 54.7075 1199.97 32.3325 1199.88 10.2613C1167.85 33.4988 1167.81 76.3867 1167.81 117.579C1167.81 117.579 1203.45 107.445 1216.17 75.975Z" fill="#A9B3EB"/>
                <path d="M1511.22 85.8543C1515.48 75.2009 1507.69 61.2643 1499.47 52.4731C1502.66 40.8102 1503.07 24.8155 1494.36 17.4355C1485.65 10.0458 1470.12 13.2212 1459.28 18.396C1449.28 11.7315 1434.34 6.38033 1424.68 12.4568C1415.03 18.5332 1413.22 34.4398 1414.74 46.4358C1405.38 53.9726 1395.72 66.6645 1398.47 77.8079C1401.22 88.9513 1415.64 95.606 1427.41 97.8405C1431.62 109.16 1440.59 122.352 1451.95 123.166C1459.99 123.734 1467.68 117.961 1473.54 110.787C1485.39 125.684 1493.76 141.434 1498.35 156.909C1491.33 146.325 1476.8 137.21 1446.55 137.21C1446.55 177.932 1476.09 186.243 1495.1 186.243L1526.18 186.262C1526.18 159.575 1516.6 128.34 1494.23 99.0264C1501.83 96.6056 1508.59 92.3913 1511.22 85.8543ZM1468.72 83.6981C1466.07 85.3655 1463.12 86.4901 1460.04 87.0078C1456.96 87.5255 1453.81 87.4262 1450.77 86.7154C1447.73 86.0046 1444.86 84.6964 1442.32 82.8653C1439.78 81.0343 1437.62 78.7163 1435.97 76.0437C1434.32 73.3712 1433.21 70.3964 1432.69 67.2892C1432.18 64.182 1432.28 61.0033 1432.98 57.9345C1433.69 54.8657 1434.98 51.967 1436.8 49.4038C1438.61 46.8406 1440.91 44.6631 1443.56 42.9958C1448.9 39.6297 1455.36 38.5456 1461.5 39.9819C1467.64 41.4183 1472.96 45.2576 1476.3 50.655C1479.63 56.0525 1480.71 62.5661 1479.28 68.7628C1477.86 74.9596 1474.06 80.332 1468.71 83.6981H1468.72ZM1524.43 120.039C1534.94 141.307 1540.64 163.682 1540.72 185.753C1572.75 162.515 1572.79 119.627 1572.79 78.4351C1572.79 78.4351 1537.15 88.5691 1524.43 120.039Z" fill="#A9B3EB"/>
                <path d="M1458.92 305.283C1454.62 315.936 1462.49 329.873 1470.78 338.664C1467.57 350.327 1467.15 366.322 1475.94 373.701C1484.73 381.091 1500.41 377.916 1511.35 372.741C1521.44 379.405 1536.52 384.757 1546.27 378.68C1556.01 372.604 1557.83 356.697 1556.29 344.701C1565.74 337.164 1575.49 324.472 1572.72 313.329C1569.95 302.186 1555.39 295.531 1543.51 293.296C1539.26 281.977 1530.2 268.785 1518.75 267.971C1510.63 267.403 1502.87 273.176 1496.95 280.35C1484.99 265.453 1476.55 249.703 1471.91 234.228C1479 244.812 1493.67 253.927 1524.2 253.927C1524.2 213.205 1494.38 204.894 1475.19 204.894L1443.83 204.874C1443.83 231.562 1453.49 262.797 1476.07 292.111C1468.4 294.531 1461.58 298.746 1458.92 305.283ZM1501.82 307.439C1504.49 305.771 1507.47 304.647 1510.58 304.129C1513.68 303.611 1516.86 303.711 1519.93 304.422C1523 305.132 1525.9 306.441 1528.46 308.272C1531.02 310.103 1533.2 312.421 1534.87 315.093C1536.54 317.766 1537.66 320.741 1538.18 323.848C1538.7 326.955 1538.6 330.134 1537.89 333.202C1537.18 336.271 1535.87 339.17 1534.04 341.733C1532.21 344.296 1529.89 346.474 1527.21 348.141C1521.82 351.507 1515.3 352.591 1509.11 351.155C1502.91 349.719 1497.54 345.879 1494.17 340.482C1490.81 335.084 1489.72 328.571 1491.16 322.374C1492.59 316.177 1496.43 310.805 1501.83 307.439H1501.82ZM1445.59 271.098C1434.98 249.83 1429.24 227.455 1429.15 205.384C1396.83 228.622 1396.79 271.509 1396.79 312.702C1396.79 312.702 1432.76 302.568 1445.59 271.098Z" fill="#A9B3EB"/>
                <path d="M1279.08 280.977C1283.38 270.324 1275.51 256.387 1267.22 247.596C1270.44 235.933 1270.86 219.938 1262.07 212.558C1253.28 205.169 1237.6 208.344 1226.66 213.519C1216.56 206.854 1201.49 201.503 1191.74 207.58C1182 213.656 1180.17 229.563 1181.71 241.559C1172.26 249.095 1162.51 261.787 1165.29 272.931C1168.06 284.074 1182.61 290.729 1194.49 292.963C1198.75 304.283 1207.8 317.475 1219.26 318.288C1227.37 318.857 1235.14 313.084 1241.06 305.91C1253.01 320.807 1261.46 336.557 1266.1 352.032C1259.01 341.447 1244.34 332.333 1213.81 332.333C1213.81 373.055 1243.62 381.366 1262.81 381.366L1294.18 381.385C1294.18 354.698 1284.51 323.463 1261.93 294.149C1269.61 291.728 1276.43 287.514 1279.08 280.977ZM1236.18 278.821C1233.51 280.488 1230.54 281.613 1227.43 282.131C1224.32 282.648 1221.14 282.549 1218.08 281.838C1215.01 281.127 1212.11 279.819 1209.54 277.988C1206.98 276.157 1204.8 273.839 1203.14 271.167C1201.47 268.494 1200.34 265.519 1199.83 262.412C1199.31 259.305 1199.41 256.126 1200.12 253.057C1200.83 249.989 1202.14 247.09 1203.97 244.527C1205.8 241.963 1208.12 239.786 1210.79 238.119C1216.19 234.752 1222.7 233.668 1228.9 235.105C1235.1 236.541 1240.47 240.38 1243.83 245.778C1247.2 251.175 1248.28 257.689 1246.85 263.886C1245.41 270.082 1241.57 275.455 1236.17 278.821L1236.18 278.821ZM1292.41 315.162C1303.03 336.429 1308.77 358.804 1308.86 380.876C1341.18 357.638 1341.22 314.75 1341.22 273.558C1341.22 273.558 1305.25 283.692 1292.41 315.162Z" fill="#A9B3EB"/>
                <path d="M996.102 305.283C991.839 315.936 999.636 329.873 1007.85 338.664C1004.67 350.327 1004.25 366.322 1012.96 373.701C1021.67 381.091 1037.21 377.916 1048.04 372.741C1058.05 379.405 1072.98 384.757 1082.64 378.68C1092.3 372.604 1094.1 356.697 1092.58 344.701C1101.94 337.164 1111.6 324.472 1108.85 313.329C1106.11 302.186 1091.69 295.531 1079.92 293.296C1075.7 281.977 1066.73 268.785 1055.38 267.971C1047.34 267.403 1039.64 273.176 1033.78 280.35C1021.93 265.453 1013.56 249.703 1008.97 234.228C1015.99 244.812 1030.53 253.927 1060.78 253.927C1060.78 213.205 1031.24 204.894 1012.22 204.894L981.147 204.874C981.147 231.562 990.722 262.797 1013.1 292.111C1005.49 294.531 998.733 298.746 996.102 305.283ZM1038.61 307.439C1041.25 305.771 1044.2 304.647 1047.28 304.129C1050.36 303.611 1053.51 303.711 1056.55 304.422C1059.59 305.132 1062.46 306.441 1065 308.272C1067.54 310.103 1069.7 312.421 1071.35 315.093C1073 317.766 1074.12 320.741 1074.63 323.848C1075.14 326.955 1075.04 330.134 1074.34 333.202C1073.64 336.271 1072.34 339.17 1070.53 341.733C1068.71 344.296 1066.41 346.474 1063.77 348.141C1058.42 351.507 1051.96 352.591 1045.82 351.155C1039.68 349.719 1034.36 345.879 1031.03 340.482C1027.69 335.084 1026.62 328.571 1028.04 322.374C1029.46 316.177 1033.27 310.805 1038.62 307.439H1038.61ZM982.895 271.098C972.378 249.83 966.688 227.455 966.6 205.384C934.574 228.622 934.535 271.509 934.535 312.702C934.535 312.702 970.174 302.568 982.895 271.098Z" fill="#A9B3EB"/>
                <path d="M816.92 280.977C821.223 270.324 813.353 256.387 805.061 247.596C808.276 235.933 808.697 219.938 799.906 212.558C791.115 205.169 775.434 208.344 764.496 213.519C754.402 206.854 739.328 201.503 729.576 207.58C719.834 213.656 718.012 229.563 719.55 241.559C710.102 249.095 700.351 261.787 703.124 272.931C705.898 284.074 720.452 290.729 732.33 292.963C736.584 304.283 745.64 317.475 757.097 318.288C765.212 318.857 772.974 313.084 778.894 305.91C790.85 320.807 799.299 336.557 803.934 352.032C796.848 341.447 782.177 332.333 751.648 332.333C751.648 373.055 781.461 381.366 800.651 381.366L832.013 381.385C832.013 354.698 822.35 323.463 799.769 294.149C807.443 291.728 814.264 287.514 816.92 280.977ZM774.023 278.821C771.35 280.488 768.375 281.613 765.268 282.131C762.161 282.648 758.982 282.549 755.913 281.838C752.845 281.127 749.946 279.819 747.383 277.988C744.819 276.157 742.642 273.839 740.975 271.167C739.307 268.494 738.183 265.519 737.665 262.412C737.147 259.305 737.247 256.126 737.957 253.057C738.668 249.989 739.976 247.09 741.807 244.527C743.638 241.963 745.956 239.786 748.629 238.119C754.026 234.752 760.54 233.668 766.737 235.105C772.934 236.541 778.306 240.38 781.672 245.778C785.038 251.175 786.122 257.689 784.686 263.886C783.249 270.082 779.41 275.455 774.013 278.821L774.023 278.821ZM830.249 315.162C840.863 336.429 846.607 358.804 846.695 380.876C879.018 357.638 879.057 314.75 879.057 273.558C879.057 273.558 843.088 283.692 830.249 315.162Z" fill="#A9B3EB"/>
                <path d="M532.142 305.283C527.879 315.936 535.677 329.873 543.892 338.664C540.707 350.327 540.289 366.322 549 373.701C557.71 381.091 573.248 377.916 584.085 372.741C594.087 379.405 609.022 384.757 618.684 378.68C628.337 372.604 630.143 356.697 628.619 344.701C637.98 337.164 647.642 324.472 644.894 313.329C642.146 302.186 627.725 295.531 615.956 293.296C611.741 281.977 602.768 268.785 591.416 267.971C583.376 267.403 575.685 273.176 569.82 280.35C557.973 265.453 549.602 249.703 545.009 234.228C552.03 244.812 566.567 253.927 596.816 253.927C596.816 213.205 567.275 204.894 548.262 204.894L517.187 204.874C517.187 231.562 526.762 262.797 549.136 292.111C541.532 294.531 534.773 298.746 532.142 305.283ZM574.646 307.439C577.294 305.771 580.241 304.647 583.32 304.129C586.399 303.611 589.548 303.711 592.589 304.422C595.63 305.132 598.502 306.441 601.042 308.272C603.581 310.103 605.739 312.421 607.391 315.093C609.043 317.766 610.157 320.741 610.67 323.848C611.183 326.955 611.085 330.134 610.38 333.202C609.676 336.271 608.38 339.17 606.566 341.733C604.751 344.296 602.455 346.474 599.807 348.141C594.459 351.507 588.005 352.591 581.865 351.155C575.725 349.719 570.402 345.879 567.067 340.482C563.731 335.084 562.657 328.571 564.08 322.374C565.504 316.177 569.308 310.805 574.656 307.439H574.646ZM518.935 271.098C508.418 249.83 502.728 227.455 502.64 205.384C470.614 228.622 470.575 271.509 470.575 312.702C470.575 312.702 506.214 302.568 518.935 271.098Z" fill="#A9B3EB"/>
                <path d="M353.621 280.977C357.884 270.324 350.086 256.387 341.871 247.596C345.056 235.933 345.474 219.938 336.763 212.558C328.052 205.169 312.515 208.344 301.678 213.519C291.676 206.854 276.741 201.503 267.078 207.58C257.426 213.656 255.62 229.563 257.144 241.559C247.783 249.095 238.121 261.787 240.869 272.931C243.617 284.074 258.038 290.729 269.807 292.963C274.022 304.283 282.994 317.475 294.346 318.288C302.387 318.857 310.078 313.084 315.943 305.91C327.79 320.807 336.161 336.557 340.754 352.032C333.733 341.447 319.196 332.333 288.947 332.333C288.947 373.055 318.487 381.366 337.501 381.366L368.575 381.385C368.576 354.698 359.001 323.463 336.627 294.149C344.231 291.728 350.989 287.514 353.621 280.977ZM311.117 278.821C308.469 280.488 305.521 281.613 302.443 282.131C299.364 282.648 296.214 282.549 293.174 281.838C290.133 281.127 287.261 279.819 284.721 277.988C282.181 276.157 280.024 273.839 278.372 271.167C276.72 268.494 275.606 265.519 275.093 262.412C274.58 259.305 274.678 256.126 275.382 253.057C276.087 249.989 277.383 247.09 279.197 244.527C281.011 241.963 283.308 239.786 285.956 238.119C291.304 234.752 297.758 233.668 303.898 235.105C310.038 236.541 315.361 240.38 318.696 245.778C322.031 251.175 323.105 257.689 321.682 263.886C320.259 270.082 316.455 275.455 311.107 278.821L311.117 278.821ZM366.828 315.162C377.344 336.429 383.035 358.804 383.122 380.876C415.148 357.638 415.187 314.75 415.187 273.558C415.187 273.558 379.549 283.692 366.828 315.162Z" fill="#A9B3EB"/>
                <path d="M71.7785 305.283C67.5155 315.936 75.3132 329.873 83.5286 338.664C80.3434 350.327 79.9259 366.322 88.6365 373.701C97.347 381.091 112.884 377.916 123.722 372.741C133.724 379.405 148.659 384.757 158.321 378.68C167.974 372.604 169.78 356.697 168.255 344.701C177.616 337.164 187.279 324.472 184.531 313.329C181.782 302.186 167.362 295.531 155.592 293.296C151.378 281.977 142.405 268.785 131.053 267.971C123.013 267.403 115.322 273.176 109.456 280.35C97.6092 265.453 89.2385 249.703 84.6453 234.228C91.6662 244.812 106.203 253.927 136.452 253.927C136.452 213.205 106.912 204.894 87.8984 204.894L56.8239 204.874C56.8239 231.562 66.3987 262.797 88.7724 292.111C81.1689 294.531 74.4101 298.746 71.7785 305.283ZM114.283 307.439C116.931 305.771 119.878 304.647 122.957 304.129C126.036 303.611 129.185 303.711 132.226 304.422C135.266 305.132 138.139 306.441 140.678 308.272C143.218 310.103 145.375 312.421 147.027 315.093C148.68 317.766 149.794 320.741 150.307 323.848C150.82 326.955 150.721 330.134 150.017 333.202C149.313 336.271 148.017 339.17 146.202 341.733C144.388 344.296 142.091 346.474 139.443 348.141C134.095 351.507 127.642 352.591 121.502 351.155C115.362 349.719 110.039 345.879 106.703 340.482C103.368 335.084 102.294 328.571 103.717 322.374C105.14 316.177 108.944 310.805 114.292 307.439H114.283ZM58.5718 271.098C48.055 249.83 42.3645 227.455 42.2771 205.384C10.2509 228.622 10.2121 271.509 10.2121 312.702C10.2121 312.702 45.8507 302.568 58.5718 271.098Z" fill="#A9B3EB"/>
            </svg>

            <p class="hashtags">#Одностраничник #WordPress #Индивидуальный дизайн #NDA</p>
            
        </div>
        </div>
        <div class="Exec">
            <h2>Задача</h2>
            <p>Создать страницу с привлекательным дизайном для увеличения объёма клиентов и улучшения поискового индексирования</p>
        </div>
        <div class="aboutProject">
            <div class="defaultBlock">
                <img class="WhiteBackGroundImage" src="../images/FlowersKuzProject/FirstScreen.jpg" alt="">
                <div class="ScrollAbout">
                    Специализировались на быстродействии и клиенто - ориентированности
                </div>
            </div>
            <div class="defaultBlock">
                <div class="ScrollAbout">
                    Быстро, отзывчиво и со вкусом)
                </div>
                <img class="WhiteBackGroundImage" src="../images/FlowersKuzProject/SecondScreen.jpg" alt="">
            </div>
            <svg class="decorSvg decorSvgFlowers" width="1098" height="1824" viewBox="0 0 1098 1824" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1053 119.388C792.445 42.6725 247.342 -42.086 151.365 232.606C31.3947 575.971 155.076 763.43 342.453 883.453C529.829 1003.48 709.167 452.236 918.188 823.441C1127.21 1194.65 593.525 1268.27 342.453 1303.53C91.38 1338.8 918.188 2011.92 45 1695.77" stroke="#C859CA" stroke-width="88" stroke-linecap="round"/>
            </svg>
        </div>
    </main>
</template>
  
  <script>

  </script>