<template>
  <main>
    <div class="widthContainer">
      <h2 class="contactsTitle">Контакты</h2>
      <div class="ContactsBlock">
        <div class="left">
          <div class="number">
            <h3>Звоните по номеру</h3>
            <p>8-927-378-05-00</p>
            <p>8-900-319-17-53</p>
          </div>
          <div class="mail">
            <h3>Пишите на электропочту</h3>
            <p>ilia.pantelidi@gmail.com</p>
            <p>work.shaposhnikov@yandex.ru</p>
          </div>
        </div>
        <svg class="Decor" width="665" height="716" viewBox="0 0 665 716" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M82.5 83.5C82.5 83.5 185.946 214.429 251 207.5C288.43 203.513 287.848 182.328 321 164.5C354.641 146.409 439.324 105.761 477.5 104.5C560.657 101.752 573.424 278.812 549.5 358.5C532.511 415.089 383.043 417.951 381 477C377.746 571.022 579.5 613.5 579.5 613.5"
            stroke="url(#paint0_linear_5669_580)" stroke-width="208" />
          <defs>
            <linearGradient id="paint0_linear_5669_580" x1="556.5" y1="419" x2="102.124" y2="573.005"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#A5FF00" />
              <stop offset="1" stop-color="#639900" />
            </linearGradient>
          </defs>
        </svg>



        <div class="right">
          <button onclick="window.location = 'https://t.me/RuviconChief'">
            Telegram
            <svg width="41" height="34" viewBox="0 0 41 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M3.18409 14.4617C3.18409 14.4617 21.0009 7.17205 27.18 4.60533C29.5486 3.57864 37.5816 0.293083 37.5816 0.293083C37.5816 0.293083 41.2891 -1.14406 40.9802 2.34646C40.877 3.78387 40.0531 8.81469 39.2292 14.2562C37.9935 21.9567 36.6546 30.3754 36.6546 30.3754C36.6546 30.3754 36.4488 32.7369 34.6981 33.1476C32.9471 33.5583 30.0636 31.7102 29.5486 31.2994C29.1368 30.9913 21.8248 26.3712 19.1473 24.1126C18.4263 23.4967 17.6023 22.2645 19.2502 20.8271C22.9576 17.4392 27.3861 13.2295 30.0636 10.5602C31.2993 9.32803 32.5353 6.4532 27.3861 9.94399C20.0741 14.9748 12.8649 19.6978 12.8649 19.6978C12.8649 19.6978 11.2174 20.7245 8.12781 19.8004C5.03823 18.8763 1.43366 17.6444 1.43366 17.6444C1.43366 17.6444 -1.03779 16.1041 3.18464 14.4617H3.18409Z"
                fill="white" />
            </svg>
          </button>

          <button onclick="window.location = 'https://vk.com/greck_it'">
            ВКонтакте
            <svg width="52" height="31" viewBox="0 0 52 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M50.8077 2.09931C51.171 0.888171 50.8077 0 49.0898 0H43.399C41.951 0 41.2892 0.768359 40.9259 1.61225C40.9259 1.61225 38.0325 8.69417 33.9324 13.2861C32.6089 14.6222 32.0043 15.0442 31.2829 15.0442C30.9222 15.0442 30.398 14.6222 30.398 13.4111V2.09931C30.398 0.645942 29.9802 0 28.7735 0H19.8311C18.928 0 18.3831 0.671988 18.3831 1.31272C18.3831 2.68795 20.4331 3.00571 20.6433 6.88136V15.289C20.6433 17.1305 20.3138 17.4665 19.5871 17.4665C17.6591 17.4665 12.9673 10.3585 10.1828 2.22173C9.64047 0.640733 9.09292 0.00260566 7.63971 0.00260566H1.95145C0.324377 0.00260566 0 0.770964 0 1.61486C0 3.13074 1.92809 10.632 8.98134 20.5529C13.6835 27.3275 20.3034 31 26.3342 31C29.949 31 30.3954 30.1848 30.3954 28.7783V23.6576C30.3954 22.0271 30.7405 21.699 31.8849 21.699C32.7257 21.699 34.1737 22.1261 37.5498 25.3897C41.406 29.2601 42.0444 30.9974 44.2112 30.9974H49.8995C51.524 30.9974 52.3362 30.1822 51.8691 28.5725C51.3579 26.9707 49.5154 24.6396 47.0709 21.8813C45.7423 20.3081 43.7571 18.6151 43.1525 17.7686C42.3091 16.6773 42.553 16.1954 43.1525 15.2265C43.155 15.2291 50.0863 5.4306 50.8077 2.09931Z"
                fill="white" />
            </svg>
          </button>

          <button
            onclick="window.location = 'https://wa.me/79273780500?text=%D0%94%D0%BE%D0%B1%D1%80%D1%8B%D0%B9%20%D0%B4%D0%B5%D0%BD%D1%8C%2C%20%D1%8F%20%D1%85%D0%BE%D1%82%D0%B5%D0%BB%20%D0%B1%D1%8B%20%D0%BE%D0%B1%D1%81%D1%83%D0%B4%D0%B8%D1%82%D1%8C'">
            WhatsApp
            <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.02334 11.1846C8.21379 9.75001 10.1713 7.8002 11.7248 8.01656L11.7223 8.01419C13.2342 8.28784 14.431 10.7663 15.1058 11.8808C15.584 12.6894 15.2735 13.5086 14.8275 13.8543C14.2258 14.3162 13.285 14.9535 13.4824 15.5934C13.8333 16.731 18 20.6997 20.0493 21.8279C20.825 22.2549 21.3875 21.1286 21.8678 20.5516C22.2168 20.1077 23.0776 19.8424 23.9246 20.2806C25.1896 20.9822 26.3813 21.7977 27.4833 22.7157C28.0336 23.1539 28.1628 23.8014 27.7815 24.4857C27.1098 25.6911 25.1671 27.2806 23.757 26.9576C21.294 26.3936 11.3333 22.7157 8.13389 12.0607C7.95395 11.5567 7.99925 11.3661 8.02334 11.1846Z"
                fill="white" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M18 35.7542C15.9977 35.7542 14.8899 35.5409 13.0909 34.9417L9.64543 36.6527C7.46938 37.7332 4.90909 36.1617 4.90909 33.7453V30.0661C1.38526 26.8027 0 23.0399 0 17.8771C0 8.00387 8.05888 0 18 0C27.9411 0 36 8.00387 36 17.8771C36 27.7503 27.9411 35.7542 18 35.7542ZM8.18182 28.6526L7.14101 27.6887C4.40324 25.1534 3.27273 22.3189 3.27273 17.8771C3.27273 9.799 9.86636 3.25039 18 3.25039C26.1337 3.25039 32.7273 9.799 32.7273 17.8771C32.7273 25.9553 26.1337 32.5039 18 32.5039C16.387 32.5039 15.6305 32.3592 14.1316 31.86L12.843 31.4307L8.18182 33.7453V28.6526Z"
                fill="white" />
            </svg>
          </button>
        </div>
      </div>
      <button class="offerButtonContacts">
        Оставить заявку
      </button>
      <p class="taskInContact">обработаем в течение рабочих суток и проведём бесплатный аудит</p>
    </div>
  </main>
</template>
  
<script>
export default {
  name: 'Contacts',

  mounted() {
    const offerButtonContacts = document.querySelector('.offerButtonContacts');

    if (offerButtonContacts) {
      offerButtonContacts.addEventListener('click', () => {
        this.navbutOffer();
      });
    }
  },

  methods: {
    navbutOffer() {
      this.$router.push('/');
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
      }, 100);
    }
  }
};

</script>