<template>
  <main>
    <div class="widthContainer">
      <div class="firstScreenDesign">
        <h1>Дизайн</h1>
        <p>При разработке продающего дизайна сайта мы исследуем рынок, анализируем конкурентов в поисковых системах,
          выявляем лучшие образцы дизайна в отрасли, соблюдаем фирменный стиль, акцентируем внимание на удобстве
          использования, делаем яркий дизайн продающих триггеров, тщательно прорабатываем привлекательность конверсионных
          блоков.</p>
        <svg width="352" height="352" viewBox="0 0 352 352" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_6073_1841)">
            <path
              d="M117.335 352C149.719 352 176.002 325.717 176.002 293.333V234.666H117.335C84.9503 234.666 58.667 260.949 58.667 293.333C58.667 325.717 84.9503 352 117.335 352Z"
              fill="#0ACF83" />
            <path
              d="M58.667 176C58.667 143.616 84.9503 117.333 117.335 117.333H176.002V234.665H117.335C84.9503 234.667 58.667 208.384 58.667 176Z"
              fill="#A259FF" />
            <path
              d="M58.667 58.6671C58.667 26.2831 84.9503 0 117.335 0H176.002V117.333H117.335C84.9503 117.333 58.667 91.0511 58.667 58.6671Z"
              fill="#F24E1E" />
            <path
              d="M175.998 0H234.666C267.05 0 293.333 26.2831 293.333 58.6671C293.333 91.0511 267.05 117.333 234.666 117.333H175.998V0Z"
              fill="#FF7262" />
            <path
              d="M293.333 176C293.333 208.384 267.05 234.667 234.666 234.667C202.281 234.667 175.998 208.384 175.998 176C175.998 143.616 202.281 117.333 234.666 117.333C267.05 117.333 293.333 143.616 293.333 176Z"
              fill="#1ABCFE" />
          </g>
          <defs>
            <clipPath id="clip0_6073_1841">
              <rect width="352" height="352" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <Cases></Cases>
      <div class="designInner">
        <h2>Что входит в услугу веб-дизайна сайта</h2>
        <div class="flexBox">
          <div class="list">
            <div class="defaultBlock">
              <h3>Исследование</h3>
              <ul>
                <li>Изучаем ваш бизнес и целевую аудиторию.</li>
                <li>Помогаем сформулировать преимущества и УТП.</li>
                <li>Проводим анализ спроса и сайтов конкурентов. Учитываем их ошибки, внедряем полезные находки.</li>
              </ul>
            </div>
            <div class="defaultBlock">
              <h3>Прототип</h3>
              <ul>
                <li>Создаём черно-белый эскиз сайта, где показано расположение блоков, разделов и кнопок.</li>
                <li>Планируем структуру и логику сайта.</li>
                <li>Проектируем путь вашего клиента до покупки. Закладываем потенциал роста и расширения сайта.</li>
              </ul>
            </div>
            <div class="defaultBlock">
              <h3>Дизайн-макет</h3>
              <ul>
                <li>Создаём макет главной и внутренних страниц сайта.</li>
                <li>Визуализируем концепцию с учётом заранее спроектированной структуры.</li>
                <li>Делаем анимацию и правки.</li>
              </ul>
            </div>
            <div class="defaultBlock">
              <h3>Вёрстка и программирование</h3>
              <ul>
                <li>Создаём кроссбраузерную адаптивную вёрстку, автоматизируем функционал, используем последние стеки
                  технологий в веб-разработке.</li>
                <li>Внедряем CMS. Интегрируем проект с внешними сервисами. Оптимизируем и тестируем сайт, наполняем его по
                  желанию.</li>
                <li>Делаем перенос и релиз.</li>
              </ul>
            </div>
          </div>
          <div class="rightAnimation">
            <img class="pictureRightAnimationOpacity" src="./images/BeforeAnimationDesign.png" alt="Рувикон студия разработки сайтов лендингов корпоративных многостраничников не дорого дешёво">
            <img class="pictureRightAnimationOpacity" src="./images/AfterAnimationDesign.png" alt="Рувикон студия разработки сайтов Пенза Москва Саранск">
            <img class="pictureRightAnimationEnd" src="./images/EndAnimationDesign.png" alt="Партнёрство с малым бизнесом средним поддержка архитектуры бизнеса уникальный дизайн">
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Cases from './Cases.vue';

gsap.registerPlugin(ScrollTrigger);




export default {
  name: 'Design',
  components: {
    Cases
  },

  mounted() {

    const elementsToAnimate = this.$el.querySelectorAll('.pictureRightAnimation');
    const OpacityImage = this.$el.querySelectorAll('.pictureRightAnimationOpacity');

    gsap.to(elementsToAnimate, {
      scrollTrigger: {
        trigger: '.designInner',
        start: "-1000px",
        end: "20%",
        scrub: true,
      },

      opacity: 0
    });

    gsap.to(OpacityImage, {
      scrollTrigger: {
        trigger: '.designInner',
        start: "-1000px",
        end: "20%",
        scrub: true,
      },

      opacity: 0
    });
  }
};


</script>