<template>
  <section :class="{ 'marginСases': MarginCases }" >
            <div class="casesPortfolio">
            <h2>Наши кейсы</h2>
            <div class="casesMainPage">
                    <div class="PotolkiPortfolio_1 defaultColumnBlock casesBlock" @click="handleBlockClick('potolki')">
                        <h3 class="companyName">Potolki</h3>
                        <p class="date">27.07.2024</p>
                        <div class="gradientBlock"></div>
                        <div class="gradientBlock"></div>
                        <p class="titleCard">Достойные натяжные потолки для тех, кто ценит качество</p>
                    </div>
                    <div class="FlowersSaloonNDA_2 defaultColumnBlock casesBlock" @click="handleBlockClick('flowers')">
                        <h3 class="companyName">NDA</h3>
                        <p class="date">18.08.2024</p>
                        <p class="titleCard">Цветочный салон с круглосуточной доставкой</p>
                        <div class="marqueLineFlowers">
                            <svg width="881" height="110" viewBox="0 0 881 110" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M69.9022 47.7623C72.2947 41.8382 67.9184 34.0883 63.3077 29.1996C65.0953 22.7141 65.3296 13.8197 60.441 9.71587C55.5523 5.60657 46.8323 7.37237 40.7501 10.25C35.1366 6.54397 26.7545 3.56827 21.3318 6.94727C15.9145 10.3263 14.9008 19.1716 15.7564 25.8424C10.5026 30.0335 5.07989 37.0912 6.62224 43.2879C8.16459 49.4845 16.2578 53.1851 22.8632 54.4277C25.2285 60.7224 30.2643 68.0581 36.6354 68.5105C41.148 68.8266 45.4644 65.6165 48.7562 61.6271C55.4052 69.9111 60.1031 78.6693 62.6809 87.2748C58.7406 81.3888 50.5819 76.3203 33.6052 76.3203C33.6052 98.9651 50.1841 103.587 60.8552 103.587L78.2952 103.598C78.2952 88.7572 72.9215 71.3881 60.3647 55.0871C64.632 53.741 68.4252 51.3975 69.9022 47.7623ZM46.0475 46.5633C44.5614 47.4905 42.9071 48.1159 41.1793 48.4038C39.4514 48.6917 37.6838 48.6364 35.9773 48.2412C34.2708 47.8459 32.6589 47.1184 31.2335 46.1002C29.8082 45.082 28.5973 43.793 27.6701 42.3069C26.7429 40.8207 26.1175 39.1665 25.8297 37.4386C25.5418 35.7108 25.597 33.9431 25.9923 32.2366C26.3875 30.5301 27.115 28.9182 28.1332 27.4928C29.1514 26.0675 30.4404 24.8567 31.9266 23.9295C34.928 22.0576 38.5501 21.4548 41.996 22.2535C45.4419 23.0523 48.4294 25.1872 50.3013 28.1886C52.1731 31.1901 52.7759 34.8122 51.9772 38.2581C51.1784 41.704 49.0435 44.6915 46.0421 46.5633H46.0475ZM77.3142 66.7719C83.2165 78.5984 86.4102 91.0408 86.4593 103.314C104.433 90.3922 104.455 66.543 104.455 43.6367C104.455 43.6367 84.4537 49.272 77.3142 66.7719Z" fill="#A9B3EB"/>
                                <path d="M169.908 61.2787C167.516 67.2028 171.892 74.9527 176.503 79.8414C174.715 86.3269 174.481 95.2213 179.37 99.3251C184.258 103.434 192.978 101.669 199.06 98.791C204.674 102.497 213.056 105.473 218.479 102.094C223.896 98.7147 224.91 89.8694 224.054 83.1986C229.308 79.0075 234.731 71.9498 233.188 65.7531C231.646 59.5565 223.553 55.8559 216.947 54.6133C214.582 48.3186 209.546 40.9829 203.175 40.5305C198.663 40.2144 194.346 43.4245 191.054 47.4139C184.405 39.1299 179.707 30.3717 177.13 21.7662C181.07 27.6522 189.229 32.7207 206.205 32.7207C206.205 10.0759 189.626 5.45434 178.955 5.45434L161.515 5.44345C161.515 20.2838 166.889 37.6529 179.446 53.9539C175.179 55.3 171.385 57.6435 169.908 61.2787ZM193.763 62.4777C195.249 61.5505 196.903 60.9251 198.631 60.6372C200.359 60.3493 202.127 60.4046 203.833 60.7998C205.54 61.1951 207.152 61.9226 208.577 62.9408C210.002 63.959 211.213 65.248 212.14 66.7341C213.068 68.2203 213.693 69.8745 213.981 71.6024C214.269 73.3302 214.214 75.0979 213.818 76.8044C213.423 78.5109 212.696 80.1228 211.677 81.5482C210.659 82.9735 209.37 84.1843 207.884 85.1115C204.883 86.9834 201.26 87.5862 197.815 86.7875C194.369 85.9887 191.381 83.8538 189.509 80.8524C187.637 77.8509 187.035 74.2288 187.833 70.7829C188.632 67.337 190.767 64.3495 193.768 62.4777L193.763 62.4777ZM162.496 42.2691C156.594 30.4426 153.4 18.0002 153.351 5.72685C135.377 18.6488 135.355 42.498 135.355 65.4043C135.355 65.4043 155.357 59.769 162.496 42.2691Z" fill="#A9B3EB"/>
                                <path d="M327.269 47.7623C329.64 41.8382 325.304 34.0883 320.735 29.1996C322.507 22.7141 322.739 13.8197 317.895 9.71587C313.051 5.60657 304.411 7.37237 298.385 10.25C292.823 6.54397 284.518 3.56827 279.145 6.94727C273.777 10.3263 272.773 19.1716 273.62 25.8424C268.415 30.0335 263.042 37.0912 264.57 43.2879C266.098 49.4845 274.117 53.1851 280.662 54.4277C283.006 60.7224 287.995 68.0581 294.308 68.5105C298.779 68.8266 303.056 65.6165 306.317 61.6271C312.905 69.9111 317.56 78.6693 320.114 87.2748C316.21 81.3888 308.126 76.3203 291.305 76.3203C291.305 98.9651 307.732 103.587 318.305 103.587L335.585 103.598C335.585 88.7572 330.261 71.3881 317.819 55.0871C322.048 53.741 325.806 51.3975 327.269 47.7623ZM303.634 46.5633C302.161 47.4905 300.522 48.1159 298.81 48.4038C297.098 48.6917 295.347 48.6364 293.656 48.2412C291.965 47.8459 290.368 47.1184 288.955 46.1002C287.543 45.082 286.343 43.793 285.425 42.3069C284.506 40.8207 283.886 39.1665 283.601 37.4386C283.316 35.7108 283.371 33.9431 283.762 32.2366C284.154 30.5301 284.875 28.9182 285.884 27.4928C286.893 26.0675 288.17 24.8567 289.642 23.9295C292.616 22.0576 296.205 21.4548 299.619 22.2535C303.034 23.0523 305.994 25.1872 307.848 28.1886C309.703 31.1901 310.3 34.8122 309.509 38.2581C308.717 41.704 306.602 44.6915 303.628 46.5633H303.634ZM334.613 66.7719C340.462 78.5984 343.626 91.0408 343.675 103.314C361.484 90.3922 361.505 66.543 361.505 43.6367C361.505 43.6367 341.687 49.272 334.613 66.7719Z" fill="#A9B3EB"/>
                                <path d="M426.908 61.2787C424.516 67.2028 428.892 74.9527 433.503 79.8414C431.715 86.3269 431.481 95.2213 436.37 99.3251C441.258 103.434 449.978 101.669 456.06 98.791C461.674 102.497 470.056 105.473 475.479 102.094C480.896 98.7147 481.91 89.8694 481.054 83.1986C486.308 79.0075 491.731 71.9498 490.188 65.7531C488.646 59.5565 480.553 55.8559 473.947 54.6133C471.582 48.3186 466.546 40.9829 460.175 40.5305C455.663 40.2144 451.346 43.4245 448.054 47.4139C441.405 39.1299 436.707 30.3717 434.13 21.7662C438.07 27.6522 446.229 32.7207 463.205 32.7207C463.205 10.0759 446.626 5.45434 435.955 5.45434L418.515 5.44345C418.515 20.2838 423.889 37.6529 436.446 53.9539C432.179 55.3 428.385 57.6435 426.908 61.2787ZM450.763 62.4777C452.249 61.5505 453.903 60.9251 455.631 60.6372C457.359 60.3493 459.127 60.4046 460.833 60.7998C462.54 61.1951 464.152 61.9226 465.577 62.9408C467.002 63.959 468.213 65.248 469.14 66.7341C470.068 68.2203 470.693 69.8745 470.981 71.6024C471.269 73.3302 471.214 75.0979 470.818 76.8044C470.423 78.5109 469.696 80.1228 468.677 81.5482C467.659 82.9735 466.37 84.1843 464.884 85.1115C461.883 86.9834 458.26 87.5862 454.815 86.7875C451.369 85.9887 448.381 83.8538 446.509 80.8524C444.637 77.8509 444.035 74.2288 444.833 70.7829C445.632 67.337 447.767 64.3495 450.768 62.4777L450.763 62.4777ZM419.496 42.2691C413.594 30.4426 410.4 18.0002 410.351 5.72685C392.377 18.6488 392.355 42.498 392.355 65.4043C392.355 65.4043 412.357 59.769 419.496 42.2691Z" fill="#A9B3EB"/>
                                <path d="M585.269 47.7623C587.64 41.8382 583.304 34.0883 578.735 29.1996C580.507 22.7141 580.739 13.8197 575.895 9.71587C571.051 5.60657 562.411 7.37237 556.385 10.25C550.823 6.54397 542.518 3.56827 537.145 6.94727C531.777 10.3263 530.773 19.1716 531.62 25.8424C526.415 30.0335 521.042 37.0912 522.57 43.2879C524.098 49.4845 532.117 53.1851 538.662 54.4277C541.006 60.7224 545.995 68.0581 552.308 68.5105C556.779 68.8266 561.056 65.6165 564.317 61.6271C570.905 69.9111 575.56 78.6693 578.114 87.2748C574.21 81.3888 566.126 76.3203 549.305 76.3203C549.305 98.9651 565.732 103.587 576.305 103.587L593.585 103.598C593.585 88.7572 588.261 71.3881 575.819 55.0871C580.048 53.741 583.806 51.3975 585.269 47.7623ZM561.634 46.5633C560.161 47.4905 558.522 48.1159 556.81 48.4038C555.098 48.6917 553.347 48.6364 551.656 48.2412C549.965 47.8459 548.368 47.1184 546.955 46.1002C545.543 45.082 544.343 43.793 543.425 42.3069C542.506 40.8207 541.886 39.1665 541.601 37.4386C541.316 35.7108 541.371 33.9431 541.762 32.2366C542.154 30.5301 542.875 28.9182 543.884 27.4928C544.893 26.0675 546.17 24.8567 547.642 23.9295C550.616 22.0576 554.205 21.4548 557.619 22.2535C561.034 23.0523 563.994 25.1872 565.848 28.1886C567.703 31.1901 568.3 34.8122 567.509 38.2581C566.717 41.704 564.602 44.6915 561.628 46.5633H561.634ZM592.613 66.7719C598.462 78.5984 601.626 91.0408 601.675 103.314C619.484 90.3922 619.505 66.543 619.505 43.6367C619.505 43.6367 599.687 49.272 592.613 66.7719Z" fill="#A9B3EB"/>
                                <path d="M684.541 61.2787C682.171 67.2028 686.507 74.9527 691.075 79.8414C689.304 86.3269 689.072 95.2213 693.916 99.3251C698.759 103.434 707.399 101.669 713.426 98.791C718.988 102.497 727.293 105.473 732.666 102.094C738.034 98.7147 739.038 89.8694 738.19 83.1986C743.396 79.0075 748.769 71.9498 747.241 65.7531C745.712 59.5565 737.693 55.8559 731.149 54.6133C728.805 48.3186 723.815 40.9829 717.503 40.5305C713.032 40.2144 708.755 43.4245 705.493 47.4139C698.905 39.1299 694.25 30.3717 691.696 21.7662C695.6 27.6522 703.684 32.7207 720.505 32.7207C720.505 10.0759 704.078 5.45434 693.505 5.45434L676.225 5.44345C676.225 20.2838 681.55 37.6529 693.991 53.9539C689.763 55.3 686.005 57.6435 684.541 61.2787ZM708.177 62.4777C709.649 61.5505 711.289 60.9251 713.001 60.6372C714.713 60.3493 716.464 60.4046 718.155 60.7998C719.846 61.1951 721.443 61.9226 722.855 62.9408C724.267 63.959 725.467 65.248 726.386 66.7341C727.304 68.2203 727.924 69.8745 728.209 71.6024C728.495 73.3302 728.44 75.0979 728.048 76.8044C727.657 78.5109 726.936 80.1228 725.927 81.5482C724.918 82.9735 723.641 84.1843 722.168 85.1115C719.194 86.9834 715.606 87.5862 712.191 86.7875C708.777 85.9887 705.817 83.8538 703.962 80.8524C702.108 77.8509 701.51 74.2288 702.302 70.7829C703.093 67.337 705.208 64.3495 708.182 62.4777L708.177 62.4777ZM677.197 42.2691C671.349 30.4426 668.185 18.0002 668.136 5.72685C650.327 18.6488 650.305 42.498 650.305 65.4043C650.305 65.4043 670.123 59.769 677.197 42.2691Z" fill="#A9B3EB"/>
                                <path d="M841.269 47.7623C843.64 41.8382 839.304 34.0883 834.735 29.1996C836.507 22.7141 836.739 13.8197 831.895 9.71587C827.051 5.60657 818.411 7.37237 812.385 10.25C806.823 6.54397 798.518 3.56827 793.145 6.94727C787.777 10.3263 786.773 19.1716 787.62 25.8424C782.415 30.0335 777.042 37.0912 778.57 43.2879C780.098 49.4845 788.117 53.1851 794.662 54.4277C797.006 60.7224 801.995 68.0581 808.308 68.5105C812.779 68.8266 817.056 65.6165 820.317 61.6271C826.905 69.9111 831.56 78.6693 834.114 87.2748C830.21 81.3888 822.126 76.3203 805.305 76.3203C805.305 98.9651 821.732 103.587 832.305 103.587L849.585 103.598C849.585 88.7572 844.261 71.3881 831.819 55.0871C836.048 53.741 839.806 51.3975 841.269 47.7623ZM817.634 46.5633C816.161 47.4905 814.522 48.1159 812.81 48.4038C811.098 48.6917 809.347 48.6364 807.656 48.2412C805.965 47.8459 804.368 47.1184 802.955 46.1002C801.543 45.082 800.343 43.793 799.425 42.3069C798.506 40.8207 797.886 39.1665 797.601 37.4386C797.316 35.7108 797.371 33.9431 797.762 32.2366C798.154 30.5301 798.875 28.9182 799.884 27.4928C800.893 26.0675 802.17 24.8567 803.642 23.9295C806.616 22.0576 810.205 21.4548 813.619 22.2535C817.034 23.0523 819.994 25.1872 821.848 28.1886C823.703 31.1901 824.3 34.8122 823.509 38.2581C822.717 41.704 820.602 44.6915 817.628 46.5633H817.634ZM848.613 66.7719C854.462 78.5984 857.626 91.0408 857.675 103.314C875.484 90.3922 875.505 66.543 875.505 43.6367C875.505 43.6367 855.687 49.272 848.613 66.7719Z" fill="#A9B3EB"/>
                            </svg>
                            <svg width="881" height="110" viewBox="0 0 881 110" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M69.9022 47.7623C72.2947 41.8382 67.9184 34.0883 63.3077 29.1996C65.0953 22.7141 65.3296 13.8197 60.441 9.71587C55.5523 5.60657 46.8323 7.37237 40.7501 10.25C35.1366 6.54397 26.7545 3.56827 21.3318 6.94727C15.9145 10.3263 14.9008 19.1716 15.7564 25.8424C10.5026 30.0335 5.07989 37.0912 6.62224 43.2879C8.16459 49.4845 16.2578 53.1851 22.8632 54.4277C25.2285 60.7224 30.2643 68.0581 36.6354 68.5105C41.148 68.8266 45.4644 65.6165 48.7562 61.6271C55.4052 69.9111 60.1031 78.6693 62.6809 87.2748C58.7406 81.3888 50.5819 76.3203 33.6052 76.3203C33.6052 98.9651 50.1841 103.587 60.8552 103.587L78.2952 103.598C78.2952 88.7572 72.9215 71.3881 60.3647 55.0871C64.632 53.741 68.4252 51.3975 69.9022 47.7623ZM46.0475 46.5633C44.5614 47.4905 42.9071 48.1159 41.1793 48.4038C39.4514 48.6917 37.6838 48.6364 35.9773 48.2412C34.2708 47.8459 32.6589 47.1184 31.2335 46.1002C29.8082 45.082 28.5973 43.793 27.6701 42.3069C26.7429 40.8207 26.1175 39.1665 25.8297 37.4386C25.5418 35.7108 25.597 33.9431 25.9923 32.2366C26.3875 30.5301 27.115 28.9182 28.1332 27.4928C29.1514 26.0675 30.4404 24.8567 31.9266 23.9295C34.928 22.0576 38.5501 21.4548 41.996 22.2535C45.4419 23.0523 48.4294 25.1872 50.3013 28.1886C52.1731 31.1901 52.7759 34.8122 51.9772 38.2581C51.1784 41.704 49.0435 44.6915 46.0421 46.5633H46.0475ZM77.3142 66.7719C83.2165 78.5984 86.4102 91.0408 86.4593 103.314C104.433 90.3922 104.455 66.543 104.455 43.6367C104.455 43.6367 84.4537 49.272 77.3142 66.7719Z" fill="#A9B3EB"/>
                                <path d="M169.908 61.2787C167.516 67.2028 171.892 74.9527 176.503 79.8414C174.715 86.3269 174.481 95.2213 179.37 99.3251C184.258 103.434 192.978 101.669 199.06 98.791C204.674 102.497 213.056 105.473 218.479 102.094C223.896 98.7147 224.91 89.8694 224.054 83.1986C229.308 79.0075 234.731 71.9498 233.188 65.7531C231.646 59.5565 223.553 55.8559 216.947 54.6133C214.582 48.3186 209.546 40.9829 203.175 40.5305C198.663 40.2144 194.346 43.4245 191.054 47.4139C184.405 39.1299 179.707 30.3717 177.13 21.7662C181.07 27.6522 189.229 32.7207 206.205 32.7207C206.205 10.0759 189.626 5.45434 178.955 5.45434L161.515 5.44345C161.515 20.2838 166.889 37.6529 179.446 53.9539C175.179 55.3 171.385 57.6435 169.908 61.2787ZM193.763 62.4777C195.249 61.5505 196.903 60.9251 198.631 60.6372C200.359 60.3493 202.127 60.4046 203.833 60.7998C205.54 61.1951 207.152 61.9226 208.577 62.9408C210.002 63.959 211.213 65.248 212.14 66.7341C213.068 68.2203 213.693 69.8745 213.981 71.6024C214.269 73.3302 214.214 75.0979 213.818 76.8044C213.423 78.5109 212.696 80.1228 211.677 81.5482C210.659 82.9735 209.37 84.1843 207.884 85.1115C204.883 86.9834 201.26 87.5862 197.815 86.7875C194.369 85.9887 191.381 83.8538 189.509 80.8524C187.637 77.8509 187.035 74.2288 187.833 70.7829C188.632 67.337 190.767 64.3495 193.768 62.4777L193.763 62.4777ZM162.496 42.2691C156.594 30.4426 153.4 18.0002 153.351 5.72685C135.377 18.6488 135.355 42.498 135.355 65.4043C135.355 65.4043 155.357 59.769 162.496 42.2691Z" fill="#A9B3EB"/>
                                <path d="M327.269 47.7623C329.64 41.8382 325.304 34.0883 320.735 29.1996C322.507 22.7141 322.739 13.8197 317.895 9.71587C313.051 5.60657 304.411 7.37237 298.385 10.25C292.823 6.54397 284.518 3.56827 279.145 6.94727C273.777 10.3263 272.773 19.1716 273.62 25.8424C268.415 30.0335 263.042 37.0912 264.57 43.2879C266.098 49.4845 274.117 53.1851 280.662 54.4277C283.006 60.7224 287.995 68.0581 294.308 68.5105C298.779 68.8266 303.056 65.6165 306.317 61.6271C312.905 69.9111 317.56 78.6693 320.114 87.2748C316.21 81.3888 308.126 76.3203 291.305 76.3203C291.305 98.9651 307.732 103.587 318.305 103.587L335.585 103.598C335.585 88.7572 330.261 71.3881 317.819 55.0871C322.048 53.741 325.806 51.3975 327.269 47.7623ZM303.634 46.5633C302.161 47.4905 300.522 48.1159 298.81 48.4038C297.098 48.6917 295.347 48.6364 293.656 48.2412C291.965 47.8459 290.368 47.1184 288.955 46.1002C287.543 45.082 286.343 43.793 285.425 42.3069C284.506 40.8207 283.886 39.1665 283.601 37.4386C283.316 35.7108 283.371 33.9431 283.762 32.2366C284.154 30.5301 284.875 28.9182 285.884 27.4928C286.893 26.0675 288.17 24.8567 289.642 23.9295C292.616 22.0576 296.205 21.4548 299.619 22.2535C303.034 23.0523 305.994 25.1872 307.848 28.1886C309.703 31.1901 310.3 34.8122 309.509 38.2581C308.717 41.704 306.602 44.6915 303.628 46.5633H303.634ZM334.613 66.7719C340.462 78.5984 343.626 91.0408 343.675 103.314C361.484 90.3922 361.505 66.543 361.505 43.6367C361.505 43.6367 341.687 49.272 334.613 66.7719Z" fill="#A9B3EB"/>
                                <path d="M426.908 61.2787C424.516 67.2028 428.892 74.9527 433.503 79.8414C431.715 86.3269 431.481 95.2213 436.37 99.3251C441.258 103.434 449.978 101.669 456.06 98.791C461.674 102.497 470.056 105.473 475.479 102.094C480.896 98.7147 481.91 89.8694 481.054 83.1986C486.308 79.0075 491.731 71.9498 490.188 65.7531C488.646 59.5565 480.553 55.8559 473.947 54.6133C471.582 48.3186 466.546 40.9829 460.175 40.5305C455.663 40.2144 451.346 43.4245 448.054 47.4139C441.405 39.1299 436.707 30.3717 434.13 21.7662C438.07 27.6522 446.229 32.7207 463.205 32.7207C463.205 10.0759 446.626 5.45434 435.955 5.45434L418.515 5.44345C418.515 20.2838 423.889 37.6529 436.446 53.9539C432.179 55.3 428.385 57.6435 426.908 61.2787ZM450.763 62.4777C452.249 61.5505 453.903 60.9251 455.631 60.6372C457.359 60.3493 459.127 60.4046 460.833 60.7998C462.54 61.1951 464.152 61.9226 465.577 62.9408C467.002 63.959 468.213 65.248 469.14 66.7341C470.068 68.2203 470.693 69.8745 470.981 71.6024C471.269 73.3302 471.214 75.0979 470.818 76.8044C470.423 78.5109 469.696 80.1228 468.677 81.5482C467.659 82.9735 466.37 84.1843 464.884 85.1115C461.883 86.9834 458.26 87.5862 454.815 86.7875C451.369 85.9887 448.381 83.8538 446.509 80.8524C444.637 77.8509 444.035 74.2288 444.833 70.7829C445.632 67.337 447.767 64.3495 450.768 62.4777L450.763 62.4777ZM419.496 42.2691C413.594 30.4426 410.4 18.0002 410.351 5.72685C392.377 18.6488 392.355 42.498 392.355 65.4043C392.355 65.4043 412.357 59.769 419.496 42.2691Z" fill="#A9B3EB"/>
                                <path d="M585.269 47.7623C587.64 41.8382 583.304 34.0883 578.735 29.1996C580.507 22.7141 580.739 13.8197 575.895 9.71587C571.051 5.60657 562.411 7.37237 556.385 10.25C550.823 6.54397 542.518 3.56827 537.145 6.94727C531.777 10.3263 530.773 19.1716 531.62 25.8424C526.415 30.0335 521.042 37.0912 522.57 43.2879C524.098 49.4845 532.117 53.1851 538.662 54.4277C541.006 60.7224 545.995 68.0581 552.308 68.5105C556.779 68.8266 561.056 65.6165 564.317 61.6271C570.905 69.9111 575.56 78.6693 578.114 87.2748C574.21 81.3888 566.126 76.3203 549.305 76.3203C549.305 98.9651 565.732 103.587 576.305 103.587L593.585 103.598C593.585 88.7572 588.261 71.3881 575.819 55.0871C580.048 53.741 583.806 51.3975 585.269 47.7623ZM561.634 46.5633C560.161 47.4905 558.522 48.1159 556.81 48.4038C555.098 48.6917 553.347 48.6364 551.656 48.2412C549.965 47.8459 548.368 47.1184 546.955 46.1002C545.543 45.082 544.343 43.793 543.425 42.3069C542.506 40.8207 541.886 39.1665 541.601 37.4386C541.316 35.7108 541.371 33.9431 541.762 32.2366C542.154 30.5301 542.875 28.9182 543.884 27.4928C544.893 26.0675 546.17 24.8567 547.642 23.9295C550.616 22.0576 554.205 21.4548 557.619 22.2535C561.034 23.0523 563.994 25.1872 565.848 28.1886C567.703 31.1901 568.3 34.8122 567.509 38.2581C566.717 41.704 564.602 44.6915 561.628 46.5633H561.634ZM592.613 66.7719C598.462 78.5984 601.626 91.0408 601.675 103.314C619.484 90.3922 619.505 66.543 619.505 43.6367C619.505 43.6367 599.687 49.272 592.613 66.7719Z" fill="#A9B3EB"/>
                                <path d="M684.541 61.2787C682.171 67.2028 686.507 74.9527 691.075 79.8414C689.304 86.3269 689.072 95.2213 693.916 99.3251C698.759 103.434 707.399 101.669 713.426 98.791C718.988 102.497 727.293 105.473 732.666 102.094C738.034 98.7147 739.038 89.8694 738.19 83.1986C743.396 79.0075 748.769 71.9498 747.241 65.7531C745.712 59.5565 737.693 55.8559 731.149 54.6133C728.805 48.3186 723.815 40.9829 717.503 40.5305C713.032 40.2144 708.755 43.4245 705.493 47.4139C698.905 39.1299 694.25 30.3717 691.696 21.7662C695.6 27.6522 703.684 32.7207 720.505 32.7207C720.505 10.0759 704.078 5.45434 693.505 5.45434L676.225 5.44345C676.225 20.2838 681.55 37.6529 693.991 53.9539C689.763 55.3 686.005 57.6435 684.541 61.2787ZM708.177 62.4777C709.649 61.5505 711.289 60.9251 713.001 60.6372C714.713 60.3493 716.464 60.4046 718.155 60.7998C719.846 61.1951 721.443 61.9226 722.855 62.9408C724.267 63.959 725.467 65.248 726.386 66.7341C727.304 68.2203 727.924 69.8745 728.209 71.6024C728.495 73.3302 728.44 75.0979 728.048 76.8044C727.657 78.5109 726.936 80.1228 725.927 81.5482C724.918 82.9735 723.641 84.1843 722.168 85.1115C719.194 86.9834 715.606 87.5862 712.191 86.7875C708.777 85.9887 705.817 83.8538 703.962 80.8524C702.108 77.8509 701.51 74.2288 702.302 70.7829C703.093 67.337 705.208 64.3495 708.182 62.4777L708.177 62.4777ZM677.197 42.2691C671.349 30.4426 668.185 18.0002 668.136 5.72685C650.327 18.6488 650.305 42.498 650.305 65.4043C650.305 65.4043 670.123 59.769 677.197 42.2691Z" fill="#A9B3EB"/>
                                <path d="M841.269 47.7623C843.64 41.8382 839.304 34.0883 834.735 29.1996C836.507 22.7141 836.739 13.8197 831.895 9.71587C827.051 5.60657 818.411 7.37237 812.385 10.25C806.823 6.54397 798.518 3.56827 793.145 6.94727C787.777 10.3263 786.773 19.1716 787.62 25.8424C782.415 30.0335 777.042 37.0912 778.57 43.2879C780.098 49.4845 788.117 53.1851 794.662 54.4277C797.006 60.7224 801.995 68.0581 808.308 68.5105C812.779 68.8266 817.056 65.6165 820.317 61.6271C826.905 69.9111 831.56 78.6693 834.114 87.2748C830.21 81.3888 822.126 76.3203 805.305 76.3203C805.305 98.9651 821.732 103.587 832.305 103.587L849.585 103.598C849.585 88.7572 844.261 71.3881 831.819 55.0871C836.048 53.741 839.806 51.3975 841.269 47.7623ZM817.634 46.5633C816.161 47.4905 814.522 48.1159 812.81 48.4038C811.098 48.6917 809.347 48.6364 807.656 48.2412C805.965 47.8459 804.368 47.1184 802.955 46.1002C801.543 45.082 800.343 43.793 799.425 42.3069C798.506 40.8207 797.886 39.1665 797.601 37.4386C797.316 35.7108 797.371 33.9431 797.762 32.2366C798.154 30.5301 798.875 28.9182 799.884 27.4928C800.893 26.0675 802.17 24.8567 803.642 23.9295C806.616 22.0576 810.205 21.4548 813.619 22.2535C817.034 23.0523 819.994 25.1872 821.848 28.1886C823.703 31.1901 824.3 34.8122 823.509 38.2581C822.717 41.704 820.602 44.6915 817.628 46.5633H817.634ZM848.613 66.7719C854.462 78.5984 857.626 91.0408 857.675 103.314C875.484 90.3922 875.505 66.543 875.505 43.6367C875.505 43.6367 855.687 49.272 848.613 66.7719Z" fill="#A9B3EB"/>
                            </svg>
                        </div>
                        <div class="marqueLineFlowers">
                            <svg width="881" height="110" viewBox="0 0 881 110" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M69.9022 47.7623C72.2947 41.8382 67.9184 34.0883 63.3077 29.1996C65.0953 22.7141 65.3296 13.8197 60.441 9.71587C55.5523 5.60657 46.8323 7.37237 40.7501 10.25C35.1366 6.54397 26.7545 3.56827 21.3318 6.94727C15.9145 10.3263 14.9008 19.1716 15.7564 25.8424C10.5026 30.0335 5.07989 37.0912 6.62224 43.2879C8.16459 49.4845 16.2578 53.1851 22.8632 54.4277C25.2285 60.7224 30.2643 68.0581 36.6354 68.5105C41.148 68.8266 45.4644 65.6165 48.7562 61.6271C55.4052 69.9111 60.1031 78.6693 62.6809 87.2748C58.7406 81.3888 50.5819 76.3203 33.6052 76.3203C33.6052 98.9651 50.1841 103.587 60.8552 103.587L78.2952 103.598C78.2952 88.7572 72.9215 71.3881 60.3647 55.0871C64.632 53.741 68.4252 51.3975 69.9022 47.7623ZM46.0475 46.5633C44.5614 47.4905 42.9071 48.1159 41.1793 48.4038C39.4514 48.6917 37.6838 48.6364 35.9773 48.2412C34.2708 47.8459 32.6589 47.1184 31.2335 46.1002C29.8082 45.082 28.5973 43.793 27.6701 42.3069C26.7429 40.8207 26.1175 39.1665 25.8297 37.4386C25.5418 35.7108 25.597 33.9431 25.9923 32.2366C26.3875 30.5301 27.115 28.9182 28.1332 27.4928C29.1514 26.0675 30.4404 24.8567 31.9266 23.9295C34.928 22.0576 38.5501 21.4548 41.996 22.2535C45.4419 23.0523 48.4294 25.1872 50.3013 28.1886C52.1731 31.1901 52.7759 34.8122 51.9772 38.2581C51.1784 41.704 49.0435 44.6915 46.0421 46.5633H46.0475ZM77.3142 66.7719C83.2165 78.5984 86.4102 91.0408 86.4593 103.314C104.433 90.3922 104.455 66.543 104.455 43.6367C104.455 43.6367 84.4537 49.272 77.3142 66.7719Z" fill="#A9B3EB"/>
                                <path d="M169.908 61.2787C167.516 67.2028 171.892 74.9527 176.503 79.8414C174.715 86.3269 174.481 95.2213 179.37 99.3251C184.258 103.434 192.978 101.669 199.06 98.791C204.674 102.497 213.056 105.473 218.479 102.094C223.896 98.7147 224.91 89.8694 224.054 83.1986C229.308 79.0075 234.731 71.9498 233.188 65.7531C231.646 59.5565 223.553 55.8559 216.947 54.6133C214.582 48.3186 209.546 40.9829 203.175 40.5305C198.663 40.2144 194.346 43.4245 191.054 47.4139C184.405 39.1299 179.707 30.3717 177.13 21.7662C181.07 27.6522 189.229 32.7207 206.205 32.7207C206.205 10.0759 189.626 5.45434 178.955 5.45434L161.515 5.44345C161.515 20.2838 166.889 37.6529 179.446 53.9539C175.179 55.3 171.385 57.6435 169.908 61.2787ZM193.763 62.4777C195.249 61.5505 196.903 60.9251 198.631 60.6372C200.359 60.3493 202.127 60.4046 203.833 60.7998C205.54 61.1951 207.152 61.9226 208.577 62.9408C210.002 63.959 211.213 65.248 212.14 66.7341C213.068 68.2203 213.693 69.8745 213.981 71.6024C214.269 73.3302 214.214 75.0979 213.818 76.8044C213.423 78.5109 212.696 80.1228 211.677 81.5482C210.659 82.9735 209.37 84.1843 207.884 85.1115C204.883 86.9834 201.26 87.5862 197.815 86.7875C194.369 85.9887 191.381 83.8538 189.509 80.8524C187.637 77.8509 187.035 74.2288 187.833 70.7829C188.632 67.337 190.767 64.3495 193.768 62.4777L193.763 62.4777ZM162.496 42.2691C156.594 30.4426 153.4 18.0002 153.351 5.72685C135.377 18.6488 135.355 42.498 135.355 65.4043C135.355 65.4043 155.357 59.769 162.496 42.2691Z" fill="#A9B3EB"/>
                                <path d="M327.269 47.7623C329.64 41.8382 325.304 34.0883 320.735 29.1996C322.507 22.7141 322.739 13.8197 317.895 9.71587C313.051 5.60657 304.411 7.37237 298.385 10.25C292.823 6.54397 284.518 3.56827 279.145 6.94727C273.777 10.3263 272.773 19.1716 273.62 25.8424C268.415 30.0335 263.042 37.0912 264.57 43.2879C266.098 49.4845 274.117 53.1851 280.662 54.4277C283.006 60.7224 287.995 68.0581 294.308 68.5105C298.779 68.8266 303.056 65.6165 306.317 61.6271C312.905 69.9111 317.56 78.6693 320.114 87.2748C316.21 81.3888 308.126 76.3203 291.305 76.3203C291.305 98.9651 307.732 103.587 318.305 103.587L335.585 103.598C335.585 88.7572 330.261 71.3881 317.819 55.0871C322.048 53.741 325.806 51.3975 327.269 47.7623ZM303.634 46.5633C302.161 47.4905 300.522 48.1159 298.81 48.4038C297.098 48.6917 295.347 48.6364 293.656 48.2412C291.965 47.8459 290.368 47.1184 288.955 46.1002C287.543 45.082 286.343 43.793 285.425 42.3069C284.506 40.8207 283.886 39.1665 283.601 37.4386C283.316 35.7108 283.371 33.9431 283.762 32.2366C284.154 30.5301 284.875 28.9182 285.884 27.4928C286.893 26.0675 288.17 24.8567 289.642 23.9295C292.616 22.0576 296.205 21.4548 299.619 22.2535C303.034 23.0523 305.994 25.1872 307.848 28.1886C309.703 31.1901 310.3 34.8122 309.509 38.2581C308.717 41.704 306.602 44.6915 303.628 46.5633H303.634ZM334.613 66.7719C340.462 78.5984 343.626 91.0408 343.675 103.314C361.484 90.3922 361.505 66.543 361.505 43.6367C361.505 43.6367 341.687 49.272 334.613 66.7719Z" fill="#A9B3EB"/>
                                <path d="M426.908 61.2787C424.516 67.2028 428.892 74.9527 433.503 79.8414C431.715 86.3269 431.481 95.2213 436.37 99.3251C441.258 103.434 449.978 101.669 456.06 98.791C461.674 102.497 470.056 105.473 475.479 102.094C480.896 98.7147 481.91 89.8694 481.054 83.1986C486.308 79.0075 491.731 71.9498 490.188 65.7531C488.646 59.5565 480.553 55.8559 473.947 54.6133C471.582 48.3186 466.546 40.9829 460.175 40.5305C455.663 40.2144 451.346 43.4245 448.054 47.4139C441.405 39.1299 436.707 30.3717 434.13 21.7662C438.07 27.6522 446.229 32.7207 463.205 32.7207C463.205 10.0759 446.626 5.45434 435.955 5.45434L418.515 5.44345C418.515 20.2838 423.889 37.6529 436.446 53.9539C432.179 55.3 428.385 57.6435 426.908 61.2787ZM450.763 62.4777C452.249 61.5505 453.903 60.9251 455.631 60.6372C457.359 60.3493 459.127 60.4046 460.833 60.7998C462.54 61.1951 464.152 61.9226 465.577 62.9408C467.002 63.959 468.213 65.248 469.14 66.7341C470.068 68.2203 470.693 69.8745 470.981 71.6024C471.269 73.3302 471.214 75.0979 470.818 76.8044C470.423 78.5109 469.696 80.1228 468.677 81.5482C467.659 82.9735 466.37 84.1843 464.884 85.1115C461.883 86.9834 458.26 87.5862 454.815 86.7875C451.369 85.9887 448.381 83.8538 446.509 80.8524C444.637 77.8509 444.035 74.2288 444.833 70.7829C445.632 67.337 447.767 64.3495 450.768 62.4777L450.763 62.4777ZM419.496 42.2691C413.594 30.4426 410.4 18.0002 410.351 5.72685C392.377 18.6488 392.355 42.498 392.355 65.4043C392.355 65.4043 412.357 59.769 419.496 42.2691Z" fill="#A9B3EB"/>
                                <path d="M585.269 47.7623C587.64 41.8382 583.304 34.0883 578.735 29.1996C580.507 22.7141 580.739 13.8197 575.895 9.71587C571.051 5.60657 562.411 7.37237 556.385 10.25C550.823 6.54397 542.518 3.56827 537.145 6.94727C531.777 10.3263 530.773 19.1716 531.62 25.8424C526.415 30.0335 521.042 37.0912 522.57 43.2879C524.098 49.4845 532.117 53.1851 538.662 54.4277C541.006 60.7224 545.995 68.0581 552.308 68.5105C556.779 68.8266 561.056 65.6165 564.317 61.6271C570.905 69.9111 575.56 78.6693 578.114 87.2748C574.21 81.3888 566.126 76.3203 549.305 76.3203C549.305 98.9651 565.732 103.587 576.305 103.587L593.585 103.598C593.585 88.7572 588.261 71.3881 575.819 55.0871C580.048 53.741 583.806 51.3975 585.269 47.7623ZM561.634 46.5633C560.161 47.4905 558.522 48.1159 556.81 48.4038C555.098 48.6917 553.347 48.6364 551.656 48.2412C549.965 47.8459 548.368 47.1184 546.955 46.1002C545.543 45.082 544.343 43.793 543.425 42.3069C542.506 40.8207 541.886 39.1665 541.601 37.4386C541.316 35.7108 541.371 33.9431 541.762 32.2366C542.154 30.5301 542.875 28.9182 543.884 27.4928C544.893 26.0675 546.17 24.8567 547.642 23.9295C550.616 22.0576 554.205 21.4548 557.619 22.2535C561.034 23.0523 563.994 25.1872 565.848 28.1886C567.703 31.1901 568.3 34.8122 567.509 38.2581C566.717 41.704 564.602 44.6915 561.628 46.5633H561.634ZM592.613 66.7719C598.462 78.5984 601.626 91.0408 601.675 103.314C619.484 90.3922 619.505 66.543 619.505 43.6367C619.505 43.6367 599.687 49.272 592.613 66.7719Z" fill="#A9B3EB"/>
                                <path d="M684.541 61.2787C682.171 67.2028 686.507 74.9527 691.075 79.8414C689.304 86.3269 689.072 95.2213 693.916 99.3251C698.759 103.434 707.399 101.669 713.426 98.791C718.988 102.497 727.293 105.473 732.666 102.094C738.034 98.7147 739.038 89.8694 738.19 83.1986C743.396 79.0075 748.769 71.9498 747.241 65.7531C745.712 59.5565 737.693 55.8559 731.149 54.6133C728.805 48.3186 723.815 40.9829 717.503 40.5305C713.032 40.2144 708.755 43.4245 705.493 47.4139C698.905 39.1299 694.25 30.3717 691.696 21.7662C695.6 27.6522 703.684 32.7207 720.505 32.7207C720.505 10.0759 704.078 5.45434 693.505 5.45434L676.225 5.44345C676.225 20.2838 681.55 37.6529 693.991 53.9539C689.763 55.3 686.005 57.6435 684.541 61.2787ZM708.177 62.4777C709.649 61.5505 711.289 60.9251 713.001 60.6372C714.713 60.3493 716.464 60.4046 718.155 60.7998C719.846 61.1951 721.443 61.9226 722.855 62.9408C724.267 63.959 725.467 65.248 726.386 66.7341C727.304 68.2203 727.924 69.8745 728.209 71.6024C728.495 73.3302 728.44 75.0979 728.048 76.8044C727.657 78.5109 726.936 80.1228 725.927 81.5482C724.918 82.9735 723.641 84.1843 722.168 85.1115C719.194 86.9834 715.606 87.5862 712.191 86.7875C708.777 85.9887 705.817 83.8538 703.962 80.8524C702.108 77.8509 701.51 74.2288 702.302 70.7829C703.093 67.337 705.208 64.3495 708.182 62.4777L708.177 62.4777ZM677.197 42.2691C671.349 30.4426 668.185 18.0002 668.136 5.72685C650.327 18.6488 650.305 42.498 650.305 65.4043C650.305 65.4043 670.123 59.769 677.197 42.2691Z" fill="#A9B3EB"/>
                                <path d="M841.269 47.7623C843.64 41.8382 839.304 34.0883 834.735 29.1996C836.507 22.7141 836.739 13.8197 831.895 9.71587C827.051 5.60657 818.411 7.37237 812.385 10.25C806.823 6.54397 798.518 3.56827 793.145 6.94727C787.777 10.3263 786.773 19.1716 787.62 25.8424C782.415 30.0335 777.042 37.0912 778.57 43.2879C780.098 49.4845 788.117 53.1851 794.662 54.4277C797.006 60.7224 801.995 68.0581 808.308 68.5105C812.779 68.8266 817.056 65.6165 820.317 61.6271C826.905 69.9111 831.56 78.6693 834.114 87.2748C830.21 81.3888 822.126 76.3203 805.305 76.3203C805.305 98.9651 821.732 103.587 832.305 103.587L849.585 103.598C849.585 88.7572 844.261 71.3881 831.819 55.0871C836.048 53.741 839.806 51.3975 841.269 47.7623ZM817.634 46.5633C816.161 47.4905 814.522 48.1159 812.81 48.4038C811.098 48.6917 809.347 48.6364 807.656 48.2412C805.965 47.8459 804.368 47.1184 802.955 46.1002C801.543 45.082 800.343 43.793 799.425 42.3069C798.506 40.8207 797.886 39.1665 797.601 37.4386C797.316 35.7108 797.371 33.9431 797.762 32.2366C798.154 30.5301 798.875 28.9182 799.884 27.4928C800.893 26.0675 802.17 24.8567 803.642 23.9295C806.616 22.0576 810.205 21.4548 813.619 22.2535C817.034 23.0523 819.994 25.1872 821.848 28.1886C823.703 31.1901 824.3 34.8122 823.509 38.2581C822.717 41.704 820.602 44.6915 817.628 46.5633H817.634ZM848.613 66.7719C854.462 78.5984 857.626 91.0408 857.675 103.314C875.484 90.3922 875.505 66.543 875.505 43.6367C875.505 43.6367 855.687 49.272 848.613 66.7719Z" fill="#A9B3EB"/>
                            </svg>
                            <svg width="881" height="110" viewBox="0 0 881 110" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M69.9022 47.7623C72.2947 41.8382 67.9184 34.0883 63.3077 29.1996C65.0953 22.7141 65.3296 13.8197 60.441 9.71587C55.5523 5.60657 46.8323 7.37237 40.7501 10.25C35.1366 6.54397 26.7545 3.56827 21.3318 6.94727C15.9145 10.3263 14.9008 19.1716 15.7564 25.8424C10.5026 30.0335 5.07989 37.0912 6.62224 43.2879C8.16459 49.4845 16.2578 53.1851 22.8632 54.4277C25.2285 60.7224 30.2643 68.0581 36.6354 68.5105C41.148 68.8266 45.4644 65.6165 48.7562 61.6271C55.4052 69.9111 60.1031 78.6693 62.6809 87.2748C58.7406 81.3888 50.5819 76.3203 33.6052 76.3203C33.6052 98.9651 50.1841 103.587 60.8552 103.587L78.2952 103.598C78.2952 88.7572 72.9215 71.3881 60.3647 55.0871C64.632 53.741 68.4252 51.3975 69.9022 47.7623ZM46.0475 46.5633C44.5614 47.4905 42.9071 48.1159 41.1793 48.4038C39.4514 48.6917 37.6838 48.6364 35.9773 48.2412C34.2708 47.8459 32.6589 47.1184 31.2335 46.1002C29.8082 45.082 28.5973 43.793 27.6701 42.3069C26.7429 40.8207 26.1175 39.1665 25.8297 37.4386C25.5418 35.7108 25.597 33.9431 25.9923 32.2366C26.3875 30.5301 27.115 28.9182 28.1332 27.4928C29.1514 26.0675 30.4404 24.8567 31.9266 23.9295C34.928 22.0576 38.5501 21.4548 41.996 22.2535C45.4419 23.0523 48.4294 25.1872 50.3013 28.1886C52.1731 31.1901 52.7759 34.8122 51.9772 38.2581C51.1784 41.704 49.0435 44.6915 46.0421 46.5633H46.0475ZM77.3142 66.7719C83.2165 78.5984 86.4102 91.0408 86.4593 103.314C104.433 90.3922 104.455 66.543 104.455 43.6367C104.455 43.6367 84.4537 49.272 77.3142 66.7719Z" fill="#A9B3EB"/>
                                <path d="M169.908 61.2787C167.516 67.2028 171.892 74.9527 176.503 79.8414C174.715 86.3269 174.481 95.2213 179.37 99.3251C184.258 103.434 192.978 101.669 199.06 98.791C204.674 102.497 213.056 105.473 218.479 102.094C223.896 98.7147 224.91 89.8694 224.054 83.1986C229.308 79.0075 234.731 71.9498 233.188 65.7531C231.646 59.5565 223.553 55.8559 216.947 54.6133C214.582 48.3186 209.546 40.9829 203.175 40.5305C198.663 40.2144 194.346 43.4245 191.054 47.4139C184.405 39.1299 179.707 30.3717 177.13 21.7662C181.07 27.6522 189.229 32.7207 206.205 32.7207C206.205 10.0759 189.626 5.45434 178.955 5.45434L161.515 5.44345C161.515 20.2838 166.889 37.6529 179.446 53.9539C175.179 55.3 171.385 57.6435 169.908 61.2787ZM193.763 62.4777C195.249 61.5505 196.903 60.9251 198.631 60.6372C200.359 60.3493 202.127 60.4046 203.833 60.7998C205.54 61.1951 207.152 61.9226 208.577 62.9408C210.002 63.959 211.213 65.248 212.14 66.7341C213.068 68.2203 213.693 69.8745 213.981 71.6024C214.269 73.3302 214.214 75.0979 213.818 76.8044C213.423 78.5109 212.696 80.1228 211.677 81.5482C210.659 82.9735 209.37 84.1843 207.884 85.1115C204.883 86.9834 201.26 87.5862 197.815 86.7875C194.369 85.9887 191.381 83.8538 189.509 80.8524C187.637 77.8509 187.035 74.2288 187.833 70.7829C188.632 67.337 190.767 64.3495 193.768 62.4777L193.763 62.4777ZM162.496 42.2691C156.594 30.4426 153.4 18.0002 153.351 5.72685C135.377 18.6488 135.355 42.498 135.355 65.4043C135.355 65.4043 155.357 59.769 162.496 42.2691Z" fill="#A9B3EB"/>
                                <path d="M327.269 47.7623C329.64 41.8382 325.304 34.0883 320.735 29.1996C322.507 22.7141 322.739 13.8197 317.895 9.71587C313.051 5.60657 304.411 7.37237 298.385 10.25C292.823 6.54397 284.518 3.56827 279.145 6.94727C273.777 10.3263 272.773 19.1716 273.62 25.8424C268.415 30.0335 263.042 37.0912 264.57 43.2879C266.098 49.4845 274.117 53.1851 280.662 54.4277C283.006 60.7224 287.995 68.0581 294.308 68.5105C298.779 68.8266 303.056 65.6165 306.317 61.6271C312.905 69.9111 317.56 78.6693 320.114 87.2748C316.21 81.3888 308.126 76.3203 291.305 76.3203C291.305 98.9651 307.732 103.587 318.305 103.587L335.585 103.598C335.585 88.7572 330.261 71.3881 317.819 55.0871C322.048 53.741 325.806 51.3975 327.269 47.7623ZM303.634 46.5633C302.161 47.4905 300.522 48.1159 298.81 48.4038C297.098 48.6917 295.347 48.6364 293.656 48.2412C291.965 47.8459 290.368 47.1184 288.955 46.1002C287.543 45.082 286.343 43.793 285.425 42.3069C284.506 40.8207 283.886 39.1665 283.601 37.4386C283.316 35.7108 283.371 33.9431 283.762 32.2366C284.154 30.5301 284.875 28.9182 285.884 27.4928C286.893 26.0675 288.17 24.8567 289.642 23.9295C292.616 22.0576 296.205 21.4548 299.619 22.2535C303.034 23.0523 305.994 25.1872 307.848 28.1886C309.703 31.1901 310.3 34.8122 309.509 38.2581C308.717 41.704 306.602 44.6915 303.628 46.5633H303.634ZM334.613 66.7719C340.462 78.5984 343.626 91.0408 343.675 103.314C361.484 90.3922 361.505 66.543 361.505 43.6367C361.505 43.6367 341.687 49.272 334.613 66.7719Z" fill="#A9B3EB"/>
                                <path d="M426.908 61.2787C424.516 67.2028 428.892 74.9527 433.503 79.8414C431.715 86.3269 431.481 95.2213 436.37 99.3251C441.258 103.434 449.978 101.669 456.06 98.791C461.674 102.497 470.056 105.473 475.479 102.094C480.896 98.7147 481.91 89.8694 481.054 83.1986C486.308 79.0075 491.731 71.9498 490.188 65.7531C488.646 59.5565 480.553 55.8559 473.947 54.6133C471.582 48.3186 466.546 40.9829 460.175 40.5305C455.663 40.2144 451.346 43.4245 448.054 47.4139C441.405 39.1299 436.707 30.3717 434.13 21.7662C438.07 27.6522 446.229 32.7207 463.205 32.7207C463.205 10.0759 446.626 5.45434 435.955 5.45434L418.515 5.44345C418.515 20.2838 423.889 37.6529 436.446 53.9539C432.179 55.3 428.385 57.6435 426.908 61.2787ZM450.763 62.4777C452.249 61.5505 453.903 60.9251 455.631 60.6372C457.359 60.3493 459.127 60.4046 460.833 60.7998C462.54 61.1951 464.152 61.9226 465.577 62.9408C467.002 63.959 468.213 65.248 469.14 66.7341C470.068 68.2203 470.693 69.8745 470.981 71.6024C471.269 73.3302 471.214 75.0979 470.818 76.8044C470.423 78.5109 469.696 80.1228 468.677 81.5482C467.659 82.9735 466.37 84.1843 464.884 85.1115C461.883 86.9834 458.26 87.5862 454.815 86.7875C451.369 85.9887 448.381 83.8538 446.509 80.8524C444.637 77.8509 444.035 74.2288 444.833 70.7829C445.632 67.337 447.767 64.3495 450.768 62.4777L450.763 62.4777ZM419.496 42.2691C413.594 30.4426 410.4 18.0002 410.351 5.72685C392.377 18.6488 392.355 42.498 392.355 65.4043C392.355 65.4043 412.357 59.769 419.496 42.2691Z" fill="#A9B3EB"/>
                                <path d="M585.269 47.7623C587.64 41.8382 583.304 34.0883 578.735 29.1996C580.507 22.7141 580.739 13.8197 575.895 9.71587C571.051 5.60657 562.411 7.37237 556.385 10.25C550.823 6.54397 542.518 3.56827 537.145 6.94727C531.777 10.3263 530.773 19.1716 531.62 25.8424C526.415 30.0335 521.042 37.0912 522.57 43.2879C524.098 49.4845 532.117 53.1851 538.662 54.4277C541.006 60.7224 545.995 68.0581 552.308 68.5105C556.779 68.8266 561.056 65.6165 564.317 61.6271C570.905 69.9111 575.56 78.6693 578.114 87.2748C574.21 81.3888 566.126 76.3203 549.305 76.3203C549.305 98.9651 565.732 103.587 576.305 103.587L593.585 103.598C593.585 88.7572 588.261 71.3881 575.819 55.0871C580.048 53.741 583.806 51.3975 585.269 47.7623ZM561.634 46.5633C560.161 47.4905 558.522 48.1159 556.81 48.4038C555.098 48.6917 553.347 48.6364 551.656 48.2412C549.965 47.8459 548.368 47.1184 546.955 46.1002C545.543 45.082 544.343 43.793 543.425 42.3069C542.506 40.8207 541.886 39.1665 541.601 37.4386C541.316 35.7108 541.371 33.9431 541.762 32.2366C542.154 30.5301 542.875 28.9182 543.884 27.4928C544.893 26.0675 546.17 24.8567 547.642 23.9295C550.616 22.0576 554.205 21.4548 557.619 22.2535C561.034 23.0523 563.994 25.1872 565.848 28.1886C567.703 31.1901 568.3 34.8122 567.509 38.2581C566.717 41.704 564.602 44.6915 561.628 46.5633H561.634ZM592.613 66.7719C598.462 78.5984 601.626 91.0408 601.675 103.314C619.484 90.3922 619.505 66.543 619.505 43.6367C619.505 43.6367 599.687 49.272 592.613 66.7719Z" fill="#A9B3EB"/>
                                <path d="M684.541 61.2787C682.171 67.2028 686.507 74.9527 691.075 79.8414C689.304 86.3269 689.072 95.2213 693.916 99.3251C698.759 103.434 707.399 101.669 713.426 98.791C718.988 102.497 727.293 105.473 732.666 102.094C738.034 98.7147 739.038 89.8694 738.19 83.1986C743.396 79.0075 748.769 71.9498 747.241 65.7531C745.712 59.5565 737.693 55.8559 731.149 54.6133C728.805 48.3186 723.815 40.9829 717.503 40.5305C713.032 40.2144 708.755 43.4245 705.493 47.4139C698.905 39.1299 694.25 30.3717 691.696 21.7662C695.6 27.6522 703.684 32.7207 720.505 32.7207C720.505 10.0759 704.078 5.45434 693.505 5.45434L676.225 5.44345C676.225 20.2838 681.55 37.6529 693.991 53.9539C689.763 55.3 686.005 57.6435 684.541 61.2787ZM708.177 62.4777C709.649 61.5505 711.289 60.9251 713.001 60.6372C714.713 60.3493 716.464 60.4046 718.155 60.7998C719.846 61.1951 721.443 61.9226 722.855 62.9408C724.267 63.959 725.467 65.248 726.386 66.7341C727.304 68.2203 727.924 69.8745 728.209 71.6024C728.495 73.3302 728.44 75.0979 728.048 76.8044C727.657 78.5109 726.936 80.1228 725.927 81.5482C724.918 82.9735 723.641 84.1843 722.168 85.1115C719.194 86.9834 715.606 87.5862 712.191 86.7875C708.777 85.9887 705.817 83.8538 703.962 80.8524C702.108 77.8509 701.51 74.2288 702.302 70.7829C703.093 67.337 705.208 64.3495 708.182 62.4777L708.177 62.4777ZM677.197 42.2691C671.349 30.4426 668.185 18.0002 668.136 5.72685C650.327 18.6488 650.305 42.498 650.305 65.4043C650.305 65.4043 670.123 59.769 677.197 42.2691Z" fill="#A9B3EB"/>
                                <path d="M841.269 47.7623C843.64 41.8382 839.304 34.0883 834.735 29.1996C836.507 22.7141 836.739 13.8197 831.895 9.71587C827.051 5.60657 818.411 7.37237 812.385 10.25C806.823 6.54397 798.518 3.56827 793.145 6.94727C787.777 10.3263 786.773 19.1716 787.62 25.8424C782.415 30.0335 777.042 37.0912 778.57 43.2879C780.098 49.4845 788.117 53.1851 794.662 54.4277C797.006 60.7224 801.995 68.0581 808.308 68.5105C812.779 68.8266 817.056 65.6165 820.317 61.6271C826.905 69.9111 831.56 78.6693 834.114 87.2748C830.21 81.3888 822.126 76.3203 805.305 76.3203C805.305 98.9651 821.732 103.587 832.305 103.587L849.585 103.598C849.585 88.7572 844.261 71.3881 831.819 55.0871C836.048 53.741 839.806 51.3975 841.269 47.7623ZM817.634 46.5633C816.161 47.4905 814.522 48.1159 812.81 48.4038C811.098 48.6917 809.347 48.6364 807.656 48.2412C805.965 47.8459 804.368 47.1184 802.955 46.1002C801.543 45.082 800.343 43.793 799.425 42.3069C798.506 40.8207 797.886 39.1665 797.601 37.4386C797.316 35.7108 797.371 33.9431 797.762 32.2366C798.154 30.5301 798.875 28.9182 799.884 27.4928C800.893 26.0675 802.17 24.8567 803.642 23.9295C806.616 22.0576 810.205 21.4548 813.619 22.2535C817.034 23.0523 819.994 25.1872 821.848 28.1886C823.703 31.1901 824.3 34.8122 823.509 38.2581C822.717 41.704 820.602 44.6915 817.628 46.5633H817.634ZM848.613 66.7719C854.462 78.5984 857.626 91.0408 857.675 103.314C875.484 90.3922 875.505 66.543 875.505 43.6367C875.505 43.6367 855.687 49.272 848.613 66.7719Z" fill="#A9B3EB"/>
                            </svg>
                        </div>
                    </div>
                
                <div class="Evak13_3 casesBlock" @click="handleBlockClick('evak13')">
                    <h3 class="companyName">Evak13</h3>
                    <p class="date">23.10.2024</p>
                    <svg width="467" height="69" viewBox="0 0 467 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M262.563 69V0H278.54V56.304L274.002 53.176L317.868 0H334.979L277.027 69H262.563ZM296.408 35.236L307.941 25.208L336.019 69H317.679L296.408 35.236Z" fill="white"/>
                        <path d="M186.492 54.464V41.768H235.651V54.464H186.492ZM221.754 0L254.937 69H237.826L208.803 6.348H214.097L185.074 69H167.962L201.145 0H221.754Z" fill="white"/>
                        <path d="M140.152 62.1H133.062L161.99 0H179.007L145.352 69H127.295L93.7341 0H110.94L140.152 62.1Z" fill="white"/>
                        <path d="M92.1743 28.52L99.2647 40.48H14.0854V28.52H92.1743ZM47.1622 34.5L42.4353 63.756L13.14 56.028H103.992L111.082 69H0V0H80.3571L85.084 12.972H13.14L42.4353 5.244L47.1622 34.5Z" fill="white"/>
                        <path d="M361.948 6.12452C365.013 4.97891 367.709 3.25585 370.097 1.01445H388.144V6.66662H360.491C360.974 6.49646 361.459 6.31569 361.948 6.12452Z" fill="white"/>
                        <path d="M350.831 11.1887V16.8414H388.144V11.1887H350.831Z" fill="white"/>
                        <path d="M350.831 23.4459V21.3634H364.556C363.402 21.7154 362.166 22.013 360.847 22.2624C357.435 22.908 354.133 23.3383 350.831 23.4459Z" fill="white"/>
                        <path d="M369.432 27.0161V21.3634H388.144V27.0161H369.432Z" fill="white"/>
                        <path d="M369.432 31.5381V37.1904H388.144V31.5381H369.432Z" fill="white"/>
                        <path d="M369.432 47.3651V41.7124H388.144V47.3651H369.432Z" fill="white"/>
                        <path d="M369.432 51.8872V57.5394H388.144V51.8872H369.432Z" fill="white"/>
                        <path d="M369.432 67.7142V62.0614H388.144V67.7142H369.432Z" fill="white"/>
                        <path d="M451.51 67.7142C453.727 66.8211 455.753 65.7377 457.645 64.4362C458.688 63.691 459.658 62.9029 460.55 62.0614H410.389C411.381 62.8996 412.423 63.6894 413.509 64.4362C415.401 65.7377 417.471 66.8211 419.692 67.7142H451.51Z" fill="white"/>
                        <path d="M464.469 57.1203C464.389 57.2613 464.307 57.4009 464.224 57.5394H405.942C405.859 57.4362 405.776 57.3323 405.694 57.2279C404.287 55.5909 403.125 53.7945 402.158 51.8872H426.576C428.965 53.1005 431.857 53.6776 435.192 53.6776C438.497 53.6776 441.256 53.0537 443.634 51.8872H466.525C466.105 53.7287 465.413 55.4597 464.469 57.1203Z" fill="white"/>
                        <path d="M447.76 47.3651H467C466.986 45.3876 466.732 43.5034 466.207 41.7124H446.443C447.445 42.8376 447.959 44.1722 447.959 45.7162C447.959 46.2953 447.893 46.845 447.76 47.3651Z" fill="white"/>
                        <path d="M421.461 47.3651H403.599L419.892 43.5645C420.229 44.9753 420.755 46.2421 421.461 47.3651Z" fill="white"/>
                        <path d="M460.397 32.3755C461.94 33.8047 463.184 35.4097 464.216 37.1904H422.586L417.416 31.5381H459.441C459.769 31.8102 460.088 32.0894 460.397 32.3755Z" fill="white"/>
                        <path d="M451.539 27.0161C448.929 26.0081 446.145 25.307 443.116 24.8445L447.775 21.3634H427.662L419.722 27.0161H451.539Z" fill="white"/>
                        <path d="M461.393 11.1887L453.827 16.8414H434.015L436.072 15.3769H403.272V11.1887H461.393Z" fill="white"/>
                        <path d="M464.139 6.66662V1.01445H403.272V6.66662H464.139Z" fill="white"/>
                    </svg>
                    <p class="adventage">круглосуточно</p>
                    <p class="adventage">Бережно</p>
                    <p class="adventage">Оперативно</p>
                    <p class="adventage">Надёжно</p>
                </div>
            </div>
        </div>
      </section>
  </template>
  
  <script>
import { useRouter } from 'vue-router'
import { useRoute } from 'vue-router'
import { computed } from 'vue'

export default {
    name: 'Cases',

  setup() {
    const router = useRouter()
    const route = useRoute()

    const MarginCases = computed(() => {
      const excludedPaths = ['/cases']
      return excludedPaths.includes(route.path)
    })

    const handleBlockClick = (id) => {
      router.push(`/cases/${id}`)
    }

    return {
        MarginCases,
        handleBlockClick
    }
  }
}

  </script>