  <!-- Header.vue -->
  <template>
    <header :class="{ 'header--hidden': !showHeader }">
        <router-link to="/">
            <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M8.20312 21.3453C8.20312 10.5165 10.5241 8.19556 21.3529 8.19556H34.6611C45.4898 8.19556 47.811 10.5165 47.811 21.3453V34.6535C47.811 45.4823 45.4898 47.8034 34.6611 47.8034H21.3529C10.5241 47.8034 8.20312 45.4823 8.20312 34.6535V21.3453Z"
                    fill="#3952DF" />
                <path
                    d="M18.7096 9.29105C26.3667 1.63396 29.649 1.63395 37.3061 9.29108L46.7164 18.7014C54.3734 26.3584 54.3736 29.6409 46.7165 37.298L37.3062 46.7084C29.6491 54.3655 26.3665 54.3654 18.7094 46.7084L9.29911 37.2981C1.64198 29.6409 1.64207 26.3585 9.29924 18.7014L18.7096 9.29105Z"
                    fill="#3952DF" />
                <path
                    d="M17.709 19.2863L32.3379 19.2863C33.1831 19.2863 33.977 19.4177 34.7197 19.6805C35.4623 19.9697 36.077 20.364 36.6148 20.8634C37.1526 21.3628 37.5624 21.9674 37.8697 22.6771C38.1514 23.3868 38.3051 24.2017 38.3051 25.0954C38.3051 25.9891 38.1514 26.804 37.8697 27.5137C37.5624 28.2234 32.8757 36.2143 32.3379 36.7137C31.325 36.7137 33.1831 36.7137 32.3379 36.7137V30.9308H22.0627V36.372H17.709V19.2863ZM32.3379 26.7777C32.8245 26.7777 33.2343 26.62 33.516 26.3308C33.7977 26.0417 33.9514 25.6474 33.9514 25.0954C33.9514 24.5697 33.7977 24.1491 33.516 23.86C33.2343 23.5708 32.8245 23.4394 32.3379 23.4394H22.0627V26.7777L32.3379 26.7777Z"
                    fill="white" />
            </svg>
        </router-link>
        <nav>
            <router-link to="/">О нас</router-link>
            <div class="menuHover">
                <span class="menuTriger" @mouseover="showMenu = true; hideMenuTimeout = null"
                    @mouseout="startHideMenuTimeout">Услуги</span>
                <div class="menu" v-if="showMenu" @mouseover="clearHideMenuTimeout" @mouseout="startHideMenuTimeout"
                    :class="{ 'menuVisible': showMenu, 'menuHidden': !showMenu }">
                    <router-link to="/dev">Разработка</router-link>
                    <router-link to="/seo">SEO оптимизация</router-link>
                    <router-link to="/tech">Тех. поддержка</router-link>
                    <router-link to="/design">Дизайн</router-link>
                </div>
            </div>
            <router-link to="/cases">Портфолио</router-link>
            <router-link to="/contacts">Контакты</router-link>
        </nav>
        <span class="openBut" @click="openNav">
            <svg width="64px" height="64px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M4 5C3.44772 5 3 5.44772 3 6C3 6.55228 3.44772 7 4 7H20C20.5523 7 21 6.55228 21 6C21 5.44772 20.5523 5 20 5H4ZM7 12C7 11.4477 7.44772 11 8 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H8C7.44772 13 7 12.5523 7 12ZM13 18C13 17.4477 13.4477 17 14 17H20C20.5523 17 21 17.4477 21 18C21 18.5523 20.5523 19 20 19H14C13.4477 19 13 18.5523 13 18Z" fill="#000000"></path> </g>
                </svg>
        </span>
<div id="sidenav" class="sidenav" :style="{ width: sidenavWidth }">
    <a href="javascript:void(0)" class="closeBut" @click="closeNav">×</a>
    <router-link @click="closeNav" to="/">О нас</router-link>
    <router-link @click="closeNav" to="/cases">Портфолио</router-link>
    <router-link @click="closeNav" to="/contacts">Контакты</router-link>
    <router-link @click="closeNav" to="/dev">Разработка</router-link>
    <router-link @click="closeNav" to="/seo">SEO оптимизация</router-link>
    <router-link @click="closeNav" to="/tech">Тех. поддержка</router-link>
    <router-link @click="closeNav" to="/design">Дизайн</router-link>
    <div class="socialIcons">
         <a href="https://vk.com/ruvicon">
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_5314_265)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.37413 3.37413C0 6.74826 0 12.1788 0 23.04V24.96C0 35.8212 0 41.2517 3.37413 44.6259C6.74826 48 12.1788 48 23.04 48H24.96C35.8212 48 41.2517 48 44.6259 44.6259C48 41.2517 48 35.8212 48 24.96V23.04C48 12.1788 48 6.74826 44.6259 3.37413C41.2517 0 35.8212 0 24.96 0H23.04C12.1788 0 6.74826 0 3.37413 3.37413ZM8.10012 14.6001C8.36012 27.0801 14.6001 34.5801 25.5401 34.5801H26.1602V27.4401C30.1802 27.8401 33.22 30.7801 34.44 34.5801H40.1201C38.5601 28.9001 34.4599 25.7601 31.8999 24.5601C34.4599 23.0801 38.0599 19.4801 38.9199 14.6001H33.7598C32.6398 18.5601 29.3202 22.1601 26.1602 22.5001V14.6001H21V28.4401C17.8 27.6401 13.7601 23.7601 13.5801 14.6001H8.10012Z" fill="white"/>
                </g>
                <defs>
                <clipPath id="clip0_5314_265">
                <rect width="48" height="48" fill="white"/>
                </clipPath>
                </defs>
            </svg>
        </a>
        <a href="https://wa.me/79003191753?text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5">
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48L3.374 35.674C1.292 32.066 0.198 27.976 0.2 23.782C0.206 10.67 10.876 0 23.986 0C30.348 0.002 36.32 2.48 40.812 6.976C45.302 11.472 47.774 17.448 47.772 23.804C47.766 36.918 37.096 47.588 23.986 47.588C20.006 47.586 16.084 46.588 12.61 44.692L0 48ZM13.194 40.386C16.546 42.376 19.746 43.568 23.978 43.57C34.874 43.57 43.75 34.702 43.756 23.8C43.76 12.876 34.926 4.02 23.994 4.016C13.09 4.016 4.22 12.884 4.216 23.784C4.214 28.234 5.518 31.566 7.708 35.052L5.71 42.348L13.194 40.386ZM35.968 29.458C35.82 29.21 35.424 29.062 34.828 28.764C34.234 28.466 31.312 27.028 30.766 26.83C30.222 26.632 29.826 26.532 29.428 27.128C29.032 27.722 27.892 29.062 27.546 29.458C27.2 29.854 26.852 29.904 26.258 29.606C25.664 29.308 23.748 28.682 21.478 26.656C19.712 25.08 18.518 23.134 18.172 22.538C17.826 21.944 18.136 21.622 18.432 21.326C18.7 21.06 19.026 20.632 19.324 20.284C19.626 19.94 19.724 19.692 19.924 19.294C20.122 18.898 20.024 18.55 19.874 18.252C19.724 17.956 18.536 15.03 18.042 13.84C17.558 12.682 17.068 12.838 16.704 12.82L15.564 12.8C15.168 12.8 14.524 12.948 13.98 13.544C13.436 14.14 11.9 15.576 11.9 18.502C11.9 21.428 14.03 24.254 14.326 24.65C14.624 25.046 18.516 31.05 24.478 33.624C25.896 34.236 27.004 34.602 27.866 34.876C29.29 35.328 30.586 35.264 31.61 35.112C32.752 34.942 35.126 33.674 35.622 32.286C36.118 30.896 36.118 29.706 35.968 29.458Z" fill="white"/>
            </svg>
        </a>
        <a href="https://t.me/RuviconChief">
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_5314_275)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24ZM24.8601 17.7179C22.5257 18.6888 17.8603 20.6984 10.8638 23.7466C9.72766 24.1984 9.13251 24.6404 9.07834 25.0726C8.98677 25.803 9.90142 26.0906 11.1469 26.4822C11.3164 26.5355 11.4919 26.5907 11.6719 26.6492C12.8973 27.0475 14.5457 27.5135 15.4026 27.5321C16.1799 27.5489 17.0475 27.2284 18.0053 26.5707C24.5423 22.158 27.9168 19.9276 28.1286 19.8795C28.2781 19.8456 28.4852 19.803 28.6255 19.9277C28.7659 20.0524 28.7521 20.2886 28.7372 20.352C28.6466 20.7383 25.0562 24.0762 23.1982 25.8036C22.619 26.3421 22.2081 26.724 22.1242 26.8113C21.936 27.0067 21.7443 27.1915 21.56 27.3692C20.4215 28.4667 19.5678 29.2896 21.6072 30.6336C22.5873 31.2794 23.3715 31.8135 24.1539 32.3463C25.0084 32.9282 25.8606 33.5085 26.9632 34.2313C27.2442 34.4155 27.5125 34.6068 27.7738 34.7931C28.7681 35.5019 29.6615 36.1388 30.7652 36.0373C31.4065 35.9782 32.0689 35.3752 32.4053 33.5767C33.2004 29.3263 34.7633 20.1169 35.1244 16.3219C35.1561 15.9895 35.1163 15.5639 35.0843 15.3771C35.0523 15.1904 34.9855 14.9242 34.7427 14.7272C34.4552 14.4939 34.0113 14.4447 33.8127 14.4482C32.91 14.4641 31.5251 14.9456 24.8601 17.7179Z" fill="white"/>
                </g>
                <defs>
                <clipPath id="clip0_5314_275">
                <rect width="48" height="48" fill="white"/>
                </clipPath>
                </defs>
            </svg>
        </a>
    </div>
    <a href="tel:+79273780500">+7 927 378 05 00</a>
    <a href="tel:+79003191753">+7 900 319 17 53</a>
    <router-link @click="closeNav" to="/"><img src="../components/views/images/favicon.svg" alt="Логотип компании рувикон по разработке лендингов многостраничников"></router-link>
</div>

    </header>
</template>

<script>
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';


export default {
name: 'Header',

    data() {
        return {
            showMenu: false,
            hideMenuTimeout: null,
            sidenavWidth: '0px'
        }
    },
    methods: {
        startHideMenuTimeout() {
            this.hideMenuTimeout = setTimeout(() => {
                this.showMenu = false;
            }, 200);
        },
        clearHideMenuTimeout() {
            clearTimeout(this.hideMenuTimeout);
        },


        openNav() {
            this.sidenavWidth = "100%";
            const links = document.querySelectorAll(".sidenav a");
            links.forEach(link => {
                link.style.opacity = "1";
            });
        },

        closeNav() {
            this.sidenavWidth = "0px";
            const links = document.querySelectorAll(".sidenav a");
            links.forEach(link => {
                link.style.opacity = "0";
            });
        }
    },


    


    setup() {
        const route = useRoute();

const showHeader = computed(() => {
  const excludedPaths = ['/notfound']; // Пример: пути, где не должен отображаться заголовок
  return !excludedPaths.includes(route.path);
    });

    return {
      showHeader
    }
  }
}
</script>

<style>
.header--hidden {
  display: none;
}
</style>
